import React from "react";
import { StyleSheet, View, FlatList, RefreshControl } from "react-native-web";
import Link from "next/link";
import { Values } from "../../../config";

import GridCardItem from "./components/GridCardItem";
import { generateURLSlug } from "../../../../helpers";
import InfiniteScroll from "react-infinite-scroll-component";

const CardGrid = (props) => {
  const { items, hasMask, hideAdd, isMobileVersion } = props;

  const onLoadMore = () => {
    if (props.onLoadMore) {
      props.onLoadMore();
    }
  };
  const onRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh();
    }
  };
  const getItemLinkUrl = (item, category) => {
    if (props.getItemLinkUrl) {
      return props.getItemLinkUrl(item);
    }
    return generateURLSlug(
      item.id,
      item.title || item.displayName || item.nickname,
      item.startDate,
      category
    );
  };
  const renderItem = ({ item, index }) => {
    if (props.renderItem) {
      return props.renderItem({ item, index });
    }

    const url = getItemLinkUrl(item, props.category);
    return (
      <Link prefetch={false} href={url} key={"card-" + index}>
        <a>
          <GridCardItem
            item={item}
            hasMask={hasMask}
            hideAdd={hideAdd}
            index={index}
            onItemAction={props.onItemAction}
            renderInfoView={props.renderInfoView}
            renderBackground={props.renderBackground}
            category={props.category}
            isMobileVersion={isMobileVersion}
          />
        </a>
      </Link>
    );
  };

  const loadingSkeleton = (key) => {
    return (
      <div
        key={"skeleton" + key}
        className={`animate-pulse rounded-2xl mr-3 mb-6 overflow-hidden relative`}>
        <div className="w-full bg-grayText h-250px"></div>
        <div className="absolute top-0 p-4">
          <div className="w-14 h-16 bg-white3 rounded-xl"></div>
        </div>
        <div className="absolute bottom-0 p-4">
          <div className="w-36 bg-white3 h-3 rounded-md mb-3"></div>
          <div className="w-16 bg-white3 h-3 rounded-md mb-3"></div>
          <div className="w-32 bg-white3 h-3 rounded-md"></div>
        </div>
      </div>
    );
  };
  const renderLoadingSkeletons = () => {
    const skeletons = [];
    if (props.isLoading) {
      for (let i = 0; i < 6; i++) {
        skeletons.push(loadingSkeleton(i));
      }
      return skeletons;
    }
    return null;
  };
  return (
    <View
      style={[
        styles.container,
        isMobileVersion ? { paddingLeft: 0 } : {},
        props.style,
      ]}>
      <InfiniteScroll
        dataLength={items.length}
        next={onLoadMore}
        hasMore={true}
        className={isMobileVersion ? `grid grid-cols-3` : `grid grid-cols-6`}
        scrollThreshold={0.2}>
        {items.map((item, index) => renderItem({ item, index }))}
        {renderLoadingSkeletons()}
      </InfiniteScroll>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
  },
});

export default CardGrid;
