import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  RefreshControl,
  ScrollView,
} from "react-native-web";
import { CImage } from "../../atoms";
import { Constants, Values } from "../../../config";
import AlbumInfoView from "./AlbumInfoView";

const MediaGrid = (props) => {
  const { items, isAlbum, isMobileVersion } = props;
  const ITEM_WIDTH = isMobileVersion ? "33%" : 350;
  const ITEM_HEIGHT = isMobileVersion ? (Values.deviceWidth - 6) / 3 : 350;
  const onItemAction = (type, item) => {
    if (props.onItemAction) {
      props.onItemAction(type, item);
    }
  };
  const onLoadMore = () => {
    if (props.onLoadMore) {
      props.onLoadMore();
    }
  };
  const onRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh();
    }
  };
  const renderAlbumInfoView = (item) => {
    return [
      <div
        className="bg-gradient-to-t from-realblack to-transparent"
        style={styles.mask}
        key="mask"
      />,
      <AlbumInfoView
        item={item}
        key="album-info"
        isMobileVersion={isMobileVersion}
      />,
    ];
  };
  const renderItem = ({ item, index }) => {
    if (props.renderItem) {
      return (
        <View
          style={[
            styles.itemContainer,
            {
              width: ITEM_WIDTH,
              height: ITEM_HEIGHT,
            },
            isLastItem && { marginRight: 0 },
          ]}>
          {props.renderItem({ item, index })}
        </View>
      );
    }
    const isLastItem = index % 3 == 2;

    return (
      <TouchableOpacity
        activeOpacity={1}
        style={[
          styles.itemContainer,
          {
            width: ITEM_WIDTH,
            height: ITEM_HEIGHT,
          },
          isLastItem && { marginRight: 0 },
          isMobileVersion
            ? { borderRadius: 4, marginRight: 3, marginBottom: 3 }
            : {},
        ]}
        onPress={() => onItemAction("view", item)}>
        <CImage
          imgUrl={item.image}
          style={styles.backgroundImage}
          layout="fill"
          objectFit="cover"
          blurDataURL="/images/black.png"
        />
        {item.mediaType == Constants.mediaType.MEDIA_TYPE_VIDEO && (
          <View
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <View
              style={{
                width: isMobileVersion ? 50 : 100,
                height: isMobileVersion ? 50 : 100,
                borderRadius: 50,
                backgroundColor: "rgba(0,0,0,0.7)",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CImage
                imgUrl={"Local:iconVideoPlay"}
                style={styles.playIcon}
                height={isMobileVersion ? 19 : 50}
                width={isMobileVersion ? 19 : 50}
                layout="fixed"
                objectFit="contain"
              />
            </View>
          </View>
        )}

        {isAlbum && renderAlbumInfoView(item)}
      </TouchableOpacity>
    );
  };

  return (
    <FlatList
      numColumns={3}
      data={items}
      renderItem={renderItem}
      ListFooterComponent={
        <View style={{ height: isMobileVersion ? 0 : 50 }} />
      }
      refreshControl={
        <RefreshControl
          refreshing={props.isLoading || false}
          onRefresh={onRefresh}
          tintColor="#fff"
          titleColor="#fff"
        />
      }
      style={[styles.container, props.style]}
      keyExtractor={(item, index) => index.toString()}
      onEndReachedThreshold={0.4}
      onEndReached={onLoadMore}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  itemContainer: {
    borderRadius: 16,
    flexDirection: "column",
    marginRight: 10,
    marginBottom: 10,
    justifyContent: "flex-end",
    overflow: "hidden",

    backgroundColor: "black",
  },
  backgroundImage: {
    position: "absolute",
    borderRadius: 16,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "black",
  },
  mask: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  playIcon: {
    width: 40,
    height: 40,
  },
});

export default MediaGrid;
