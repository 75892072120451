import React from 'react'
import { CModal } from '../../atoms'
import SingleSelectContainer from './SingleSelectContainer'

const SingleSelectModal = React.forwardRef((props, ref) => {
  return (
    <CModal ref={ref} {...props}>
      <SingleSelectContainer {...props} style={{ marginTop: 14 }} />
    </CModal>
  )
})
export default SingleSelectModal
