import React, { useImperativeHandle, useRef, useState } from "react";
import { View, Text, StyleSheet } from "react-native-web";
import { Colors, Fonts } from "../../../config";
import { CModal, IconizedButton } from "../../atoms";
import { getClass } from "../../../helpers/formatHelpers";

const AlertModal = React.forwardRef((props, ref) => {
  const { isMobileVersion } = props;
  const [hideButton, setHideButton] = useState(props.hideButtonOk || false);
  const [title, setTitle] = useState(props.title || "");
  const [description, setDescription] = useState(props.description || "");
  const [customContent, setCustomContent] = useState(
    props.customContent || null
  );
  const alertModalRef = useRef(null);
  useImperativeHandle(ref, () => ({
    alert: (title = "", description = "", customContent = null) => {
      setTitle(title);
      setDescription(description);
      setCustomContent(customContent);
      alertModalRef.current.showModal();
    },
    hideModal: () => {
      alertModalRef.current.hideModal();
    },
  }));
  return (
    <CModal ref={alertModalRef} title={title} {...props}>
      <View style={styles.container}>
        {customContent ? (
          customContent
        ) : (
          <Text
            style={[
              styles.text,
              isMobileVersion
                ? {
                    fontSize: 12,
                    lineHeight: 15,
                  }
                : {},
            ]}>
            {description}
          </Text>
        )}
      </View>
      {hideButton ?? (
        <View style={{ width: "100%" }}>
          <IconizedButton
            buttonTitle="OK"
            style={[{ marginTop: 32, flex: 1 }]}
            filled
            onPress={() => alertModalRef.current.hideModal()}
          />
        </View>
      )}
    </CModal>
  );
});
const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: 14,
  },
  text: {
    fontSize: 16,
    lineHeight: 15,
    fontFamily: Fonts.primaryRegular,
    color: getClass("text-white"),
    textAlign: "center",
    marginBottom: 16,
  },
});
export default AlertModal;
