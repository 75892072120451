import React, { useState } from "react";
import { StyleSheet, ScrollView } from "react-native-web";
import { CCheckBox, IconizedTextInput } from "../../atoms";
import { useTranslation } from "react-i18next";

const SingleSelectContainer = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  const [search, setSearch] = useState("");

  const onPressItem = (item) => {
    if (props.onPressItem) {
      props.onPressItem(item);
    }
  };
  const isChecked = (item) => {
    if (item.id == props.checkedItemId) {
      return true;
    }
    return false;
  };
  const renderItems = (items) => {
    return items.map((item, index) => {
      const isLast = index == items.length - 1;
      return (
        <CCheckBox
          key={"check" + index}
          label={item.label}
          suffix={item.suffix}
          checked={isChecked(item)}
          onPress={() => {
            onPressItem(item);
          }}
          style={[!isLast && styles.itemStyle]}
          isMobileVersion={isMobileVersion}
        />
      );
    });
  };
  return (
    <ScrollView style={[styles.container, props.style]}>
      {props.isSearchable && (
        <IconizedTextInput
          placeholder={t("key_search")}
          bordered
          onChangeText={(text) => setSearch(text)}
          value={search}
          maxLength={64}
          autoCapitalize="none"
          autoCorrect={false}
          containerStyle={{ marginBottom: 16, marginTop: 16 }}
          returnKeyType="search"
          showClose={search !== ""}
          onPressClose={() => setSearch("")}
        />
      )}
      {renderItems(
        props.items
          .filter((item) =>
            item.label.toLowerCase().includes(search.toLowerCase())
          )
          .filter((item, index) => index < 20)
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
    maxHeight: 350,
    overflow: "hidden",
  },
  itemStyle: {
    marginBottom: 14,
  },
});

export default SingleSelectContainer;
