import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts, Constants } from "../../../config";
import { CImage } from "../../../components/atoms";
import ImagePreview from "./components/ImagePreview";
import VideoPreview from "./components/VideoPreview";

const Media = (props) => {
  if (!props.item) return null;
  const { isFull, item, isPlay, isAutoHeight, height, width } = props;
  const { mediaType } = item;
  return (
    <View style={[styles.container, props.style]}>
      {mediaType == Constants.mediaType.MEDIA_TYPE_IMAGE && (
        <ImagePreview
          style={[{ flex: 1, justifyContent: "center" }, props.mediaStyle]}
          item={item}
          height={height}
          width={width}
          isAutoHeight={isAutoHeight}
          isFull={isFull}
        />
      )}
      {mediaType == Constants.mediaType.MEDIA_TYPE_VIDEO && (
        <VideoPreview
          style={[{ flex: 1 }, props.mediaStyle]}
          item={item}
          isPlay={isPlay}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Media;
