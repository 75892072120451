import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, Image } from "react-native-web";
import { Colors, Constants, Fonts, Images, Values } from "../../../../config";
import { formatNumber } from "../../../../helpers";
import {
  formatCurrency,
  formatTagRange,
  getClass,
  getSpecialist,
} from "../../../../helpers/formatHelpers";
import { CImage, DotSplitedText, ReadMoreText } from "../../../atoms";
import DotSplitedViews from "../../../atoms/DotSplitedViews";
import { minBy } from "lodash";

const UserInfoView = (props) => {
  const {
    categoryType,
    item,
    isMobileVersion,
    firstIndex,
    lastIndex,
    isGuides,
    isHeading,
    withoutBadge,
  } = props;
  const {
    displayName,
    nickname,
    country,
    location,
    totalEvent,
    totalConnection,
    totalFollower,
    togetherFollowing,
    isOfficial,
    isPro,
    description,
    dist,
  } = item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  let specialist = getSpecialist(item.specialist?.split(","));
  if (!item.specialist) {
    specialist = getSpecialist(item.genres);
  }
  const totalFollowerString = totalFollower || "-";
  const totalEventString = Number(totalEvent) || "-";
  let totalShareString = formatNumber(totalConnection) || "-";
  const genreString = specialist || "-";
  const locationString = country || location || "-";
  const getLocation = (location) => {
    const locationArray = location.split("");
    if (locationArray[0] === ",") {
      if (locationArray.length === 2) {
        return "-";
      } else {
        locationArray.shift();
      }
    }
    return locationArray;
  };
  const renderViews = () => {
    return isMobileVersion
      ? [
          <View key={1} style={styles.rowContainer}>
            {categoryType !== Constants.categoryType.CATEGORY_VENUE &&
              categoryType !== Constants.categoryType.CATEGORY_ROOFTOP &&
              categoryType !== Constants.categoryType.CATEGORY_SPA &&
              categoryType !== Constants.categoryType.CATEGORY_VENUE_BEACHFUL &&
              categoryType !== Constants.categoryType.CATEGORY_PREFAB_COMPANY &&
              categoryType !== Constants.categoryType.CATEGORY_PREFAB_HOMES &&
              categoryType !== Constants.categoryType.CATEGORY_ZOO &&
              categoryType !== Constants.categoryType.CATEGORY_ANIMAL &&
              categoryType !== Constants.categoryType.CATEGORY_PARKFUL &&
              categoryType !== Constants.categoryType.CATEGORY_CASINO &&
              categoryType !== Constants.categoryType.CATEGORY_CASTLE &&
              categoryType !== Constants.categoryType.CATEGORY_RESORT &&
              categoryType !== Constants.categoryType.CATEGORY_APARTHOTEL &&
              categoryType !==
                Constants.categoryType.CATEGORY_VENUE_COCKTAIL && (
                <CImage
                  key={1}
                  source={Images.iconTicket}
                  width={isMobileVersion ? 12 : 16}
                  height={isMobileVersion ? 12 : 16}
                  layout="fixed"
                  alt="ticket entry"
                />
              )}
            {categoryType !== Constants.categoryType.CATEGORY_VENUE &&
              categoryType !== Constants.categoryType.CATEGORY_ROOFTOP &&
              categoryType !== Constants.categoryType.CATEGORY_SPA &&
              categoryType !== Constants.categoryType.CATEGORY_VENUE_BEACHFUL &&
              categoryType !== Constants.categoryType.CATEGORY_PREFAB_COMPANY &&
              categoryType !== Constants.categoryType.CATEGORY_PREFAB_HOMES &&
              categoryType !== Constants.categoryType.CATEGORY_ZOO &&
              categoryType !== Constants.categoryType.CATEGORY_ANIMAL &&
              categoryType !== Constants.categoryType.CATEGORY_PARKFUL &&
              categoryType !== Constants.categoryType.CATEGORY_CASINO &&
              categoryType !== Constants.categoryType.CATEGORY_CASTLE &&
              categoryType !== Constants.categoryType.CATEGORY_RESORT &&
              categoryType !== Constants.categoryType.CATEGORY_APARTHOTEL &&
              categoryType !==
                Constants.categoryType.CATEGORY_VENUE_COCKTAIL && (
                <Text
                  key={2}
                  style={[
                    styles.infoText,
                    { marginLeft: 4, marginRight: 10 },
                    isMobileVersion
                      ? {
                          fontSize: 10,
                          lineHeight: 18,
                        }
                      : {},
                  ]}>
                  {totalEventString}
                </Text>
              )}
            {/* <CImage
              key={3}
              source={Images.iconSearchOther}
              width={isMobileVersion ? 18 : 20}
              height={isMobileVersion ? 18 : 20}
              layout="fixed"
              alt="followers"
            />
            <Text
              key={4}
              style={[
                styles.infoText,
                { marginLeft: 4 },
                isMobileVersion
                  ? {
                      fontSize: 10,
                      lineHeight: 18,
                    }
                  : {},
              ]}>
              {totalFollowerString}
            </Text> */}
          </View>,
        ]
      : [
          <View key={1} style={styles.rowContainer}>
            {categoryType !== Constants.categoryType.CATEGORY_VENUE && (
              <CImage
                key={1}
                source={Images.iconTicket}
                width={isMobileVersion ? 12 : 16}
                height={isMobileVersion ? 12 : 16}
                layout="fixed"
                alt="ticket entry"
              />
            )}
            {categoryType !== Constants.categoryType.CATEGORY_VENUE && (
              <Text
                key={2}
                style={[styles.infoText, { marginLeft: 4, marginRight: 10 }]}>
                {totalEventString}
              </Text>
            )}
            {/* <CImage
              key={3}
              source={Images.iconSearchOther}
              width={isMobileVersion ? 18 : 20}
              height={isMobileVersion ? 18 : 20}
              layout="fixed"
              alt="followers"
            />
            <Text key={4} style={[styles.infoText, { marginLeft: 4 }]}>
              {totalFollowerString}
            </Text> */}
          </View>,
        ];
  };

  const infoViews = () => {
    return (
      <View style={isMobileVersion ? {} : styles.rowContainer}>
        {categoryType === Constants.categoryType.CATEGORY_VENUE ||
        categoryType === Constants.categoryType.CATEGORY_SPA ||
        categoryType === Constants.categoryType.CATEGORY_ANIMAL ||
        categoryType === Constants.categoryType.CATEGORY_PARKFUL ||
        categoryType === Constants.categoryType.CATEGORY_CASINO ||
        categoryType === Constants.categoryType.CATEGORY_CASTLE ||
        categoryType === Constants.categoryType.CATEGORY_RESORT ||
        categoryType === Constants.categoryType.CATEGORY_APARTHOTEL ? (
          <DotSplitedText
            texts={[
              categoryType === Constants.categoryType.CATEGORY_ANIMAL
                ? props.item.tag_habitat
                    ?.filter((i, index) => index <= 1)
                    ?.join(", ") +
                    (props.item.tag_habitat?.length > 2
                      ? " +" + (props.item.tag_habitat?.length - 2)
                      : "") || "-"
                : props.item.city || "-",
              categoryType === Constants.categoryType.CATEGORY_ANIMAL ||
              categoryType === Constants.categoryType.CATEGORY_PARKFUL ? (
                props.item.tag_category
                  ?.filter((i, index) => index <= 1)
                  ?.join(", ") +
                  (props.item.tag_category?.length > 2
                    ? " +" + (props.item.tag_category?.length - 2)
                    : "") || "-"
              ) : categoryType === Constants.categoryType.CATEGORY_CASINO ? (
                props.item.tag_category || "-"
              ) : categoryType ===
                Constants.categoryType.CATEGORY_CASTLE ? null : categoryType === // ? props.item.tag_category || "-"
                Constants.categoryType.CATEGORY_RESORT ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 2,
                  }}>
                  <CImage
                    key={1}
                    width={16}
                    height={16}
                    layout="fixed"
                    source={Images.iconTagStar}
                    style={styles.icon}
                    resizeMode="contain"
                    alt="star"
                  />
                  <Text style={{ marginLeft: 4 }}>
                    {props.item?.tag_star || "-"}
                  </Text>
                </View>
              ) : categoryType ===
                Constants.categoryType.CATEGORY_APARTHOTEL ? (
                props.item.tag_category || "-"
              ) : categoryType === Constants.categoryType.CATEGORY_SPA ? (
                props.item.tag_venue_2 || "-"
              ) : (
                props.item.tag_venue_1 || "-"
              ),
              categoryType === Constants.categoryType.CATEGORY_ANIMAL
                ? null
                : categoryType === Constants.categoryType.CATEGORY_PARKFUL
                ? props.item?.tag_price
                  ? null
                  : // `From ${formatCurrency(
                    //     formatNumber(props.item?.tag_price)
                    //   )}`
                    null
                : categoryType === Constants.categoryType.CATEGORY_CASINO
                ? props.item?.tag_feature?.filter((i, index) => index <= 0)
                    ?.length > 0
                  ? props.item.tag_feature
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ") +
                      (props.item.tag_feature?.length > 2
                        ? " +" + (props.item.tag_feature?.length - 2)
                        : "") || "-"
                  : "-"
                : categoryType === Constants.categoryType.CATEGORY_CASTLE
                ? null
                : // ? props.item.tag_area?.join(", ")
                categoryType === Constants.categoryType.CATEGORY_RESORT
                ? props.item?.tag_feature?.filter((i, index) => index <= 0)
                    ?.length > 0
                  ? props.item.tag_feature
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ") +
                      (props.item.tag_feature?.length > 2
                        ? " +" + (props.item.tag_feature?.length - 2)
                        : "") || "-"
                  : "-"
                : categoryType === Constants.categoryType.CATEGORY_APARTHOTEL
                ? !isMobileVersion
                  ? props.item?.tag_feature?.filter((i, index) => index <= 0)
                      ?.length > 0
                    ? props.item.tag_feature
                        ?.filter((i, index) => index <= 1)
                        ?.join(", ") +
                        (props.item.tag_feature?.length > 2
                          ? " +" + (props.item.tag_feature?.length - 2)
                          : "") || "-"
                    : "-"
                  : null
                : props.item?.tag_feature?.filter((i, index) => index <= 0)
                    ?.length > 0
                ? props.item.tag_feature
                    ?.filter((i, index) => index <= 1)
                    ?.join(", ") +
                    (props.item.tag_feature?.length > 2
                      ? " +" + (props.item.tag_feature?.length - 2)
                      : "") || "-"
                : "-",
            ].filter((i) => i !== null)}
            style={{ marginBottom: isMobileVersion && !isGuides ? 10 : 0 }}
            textStyle={{
              color: getClass("text-darkGray"),
              fontSize: isMobileVersion ? 10 : 14,
            }}
          />
        ) : (
          <>
            {categoryType === Constants.categoryType.CATEGORY_PREFAB_HOMES ? (
              <DotSplitedText
                texts={[
                  props.item.company_name || "-",
                  props.item?.tag_category?.filter((i, index) => index <= 0)
                    ?.length > 0
                    ? props.item?.tag_category
                        ?.filter((i, index) => index <= 0)
                        ?.join(", ") +
                        (props.item?.tag_category?.length > 1
                          ? " +" + (props.item?.tag_category?.length - 1)
                          : "") || "-"
                    : "-",
                  props.item?.tag_price
                    ? `From ${formatCurrency(
                        formatNumber(props.item?.tag_price)
                      )}`
                    : null,
                  `Min ${formatNumber(props.item?.tag_size)} sqft`,
                ].filter((i) => i !== null)}
                style={{ marginBottom: isMobileVersion && !isGuides ? 10 : 0 }}
                textStyle={{
                  color: getClass("text-darkGray"),
                  fontSize: isMobileVersion ? 10 : 14,
                }}
              />
            ) : (
              <>
                {categoryType ===
                Constants.categoryType.CATEGORY_PREFAB_COMPANY ? (
                  <DotSplitedText
                    texts={[
                      props.item.city || "-",
                      `${props.item.company_homes?.length} Homes`,
                      minBy(props.item.company_homes, "minPrice")?.minPrice
                        ? `From ${formatCurrency(
                            formatNumber(
                              minBy(props.item.company_homes, "minPrice")
                                ?.minPrice
                            )
                          )}`
                        : null,
                    ].filter((i) => i !== null)}
                    style={{
                      marginBottom: isMobileVersion && !isGuides ? 10 : 0,
                    }}
                    textStyle={{
                      color: getClass("text-darkGray"),
                      fontSize: isMobileVersion ? 10 : 14,
                    }}
                  />
                ) : (
                  <>
                    {categoryType === Constants.categoryType.CATEGORY_ZOO ? (
                      <DotSplitedText
                        texts={[
                          props.item.city || "-",
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                            ","
                          ).find((i) => i === "Animals")
                            ? `${props.item.company_homes?.length} Animals`
                            : null,
                          props.item.tag_price
                            ? null
                            : //  `From ${formatCurrency(
                              //     formatNumber(props.item.tag_price)
                              //   )}`
                              null,
                          props.item.tag_species_animal
                            ? `Animals ${formatTagRange(
                                Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES,
                                props.item.tag_species_animal
                              )}`
                            : null,
                        ].filter((i) => i !== null)}
                        style={{
                          marginBottom: isMobileVersion && !isGuides ? 10 : 0,
                        }}
                        textStyle={{
                          color: getClass("text-darkGray"),
                          fontSize: isMobileVersion ? 10 : 14,
                        }}
                      />
                    ) : (
                      <>
                        {categoryType ===
                        Constants.categoryType.CATEGORY_ARTIST ? (
                          <DotSplitedText
                            texts={[
                              genreString,
                              // locationString,
                              <View style={styles.rowContainer}>
                                <CImage
                                  key={1}
                                  source={Images.iconTicket}
                                  width={16}
                                  height={16}
                                  layout="fixed"
                                  alt="ticket entry"
                                />
                                <Text
                                  key={2}
                                  style={[
                                    styles.infoText,
                                    { marginLeft: 4, marginRight: 10 },
                                  ]}>
                                  {totalEventString}
                                </Text>
                              </View>,
                            ].filter((i) => i !== null)}
                            style={{
                              marginBottom:
                                isMobileVersion && !isGuides ? 10 : 0,
                            }}
                            textStyle={{
                              color: getClass("text-darkGray"),
                              fontSize: isMobileVersion ? 10 : 14,
                            }}
                          />
                        ) : (
                          <DotSplitedText
                            texts={[
                              props.item.city || "-",
                              props.item.tag_venue_2 || "-",
                              isGuides
                                ? null
                                : props.item.tag_service
                                    ?.filter((i, index) => index <= 1)
                                    ?.join(", ") +
                                    (props.item.tag_service?.length > 2
                                      ? " +" +
                                        (props.item.tag_service?.length - 2)
                                      : "") || "-",
                            ].filter((i) => i !== null)}
                            style={{
                              marginBottom:
                                isMobileVersion && !isGuides ? 10 : 0,
                            }}
                            textStyle={{
                              color: getClass("text-darkGray"),
                              fontSize: isMobileVersion ? 10 : 14,
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {categoryType === Constants.categoryType.CATEGORY_VENUE && (
          <View
            style={[
              styles.rowContainer,
              { marginLeft: isMobileVersion ? 0 : 10 },
            ]}>
            <DotSplitedViews views={renderViews()} />
            {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
            <CImage
              height={isMobileVersion ? 12 : 14}
              width={isMobileVersion ? 12 : 14}
              layout="fixed"
              source={Images.iconShareColored}
              alt="shared followers"
            />
            <Text
              style={[
                styles.infoText,
                { marginLeft: 4 },
                isMobileVersion
                  ? {
                      fontSize: 10,
                      lineHeight: 18,
                    }
                  : {},
              ]}>
              {"-"}
            </Text>
          </View> */}
          </View>
        )}
      </View>
    );
  };

  return (
    <View style={[styles.infoContainer, { marginLeft: 10 }]}>
      <View
        style={[
          styles.rowContainer,
          {
            marginBottom: isGuides && isMobileVersion ? 0 : 11,
            marginRight: 24,
            justifyContent: "space-between",
            alignItems: "center",
          },
        ]}>
        <View
          style={[styles.rowContainer, { flex: 1 }]}
          onMouseOver={() => setShowPopupOfficial(true)}
          onMouseLeave={() => setShowPopupOfficial(false)}>
          <Text
            ellipsizeMode={"tail"}
            numberOfLines={1}
            style={[
              styles.title,
              isMobileVersion
                ? {
                    fontSize: 12,
                    lineHeight: 18,
                  }
                : {},
              {
                marginBottom: 0,
                marginRight: 10,
              },
              isGuides && !isMobileVersion
                ? { fontSize: 20, lineHeight: 30 }
                : {},
            ]}>
            {isGuides || isHeading ? (
              <h2>{displayName || nickname}</h2>
            ) : (
              displayName || nickname
            )}
          </Text>
          {(isOfficial === 1 || isPro === 1) && !withoutBadge && (
            <View
              style={{
                marginLeft: 6,
                marginTop: 0,
                position: "relative",
              }}>
              <CImage
                source={Images.iconVerifiedBadge}
                alt="badge official"
                width={14}
                height={14}
                layout="fixed"
              />

              {showPopupOfficial &&
                (isOfficial === 1 || isPro === 1) &&
                !isMobileVersion && (
                  <View
                    style={{
                      position: "absolute",
                      width: isMobileVersion ? 260 : 336,
                      height: isMobileVersion ? 140 : 182,
                      zIndex: 999,
                      left: 0,
                      top: lastIndex ? -110 : firstIndex ? -20 : -91,
                      paddingLeft: 24,
                    }}>
                    <View
                      style={{
                        backgroundColor: getClass("bg-gray1"),
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingHorizontal: 22,
                        borderRadius: 18,
                        width: "100%",
                        height: "100%",
                        boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                      }}>
                      <CImage
                        source={Images.iconVerifiedBadgeMedium}
                        alt="verified account"
                        width={isMobileVersion ? 24 : 35}
                        height={isMobileVersion ? 24 : 35}
                        layout="fixed"
                      />
                      <Text
                        style={{
                          fontFamily: Fonts.primaryBold,
                          fontSize: isMobileVersion ? 16 : 20,
                          lineHeight: isMobileVersion ? 20 : 24,
                          color: Colors.white,
                          marginVertical: isMobileVersion ? 9 : 14,
                          textAlign: "center",
                        }}>
                        Official Account
                      </Text>
                      <Text
                        style={{
                          fontFamily: Fonts.primaryRegular,
                          fontSize: isMobileVersion ? 12 : 16,
                          lineHeight: isMobileVersion ? 16 : 18,
                          color: Colors.white80,
                          textAlign: "center",
                        }}>
                        This is an official account verified by{" "}
                        {process.env.NEXT_PUBLIC_APP_NAME}
                      </Text>
                    </View>
                  </View>
                )}
            </View>
          )}

          {showPopupOfficial &&
            (isOfficial === 1 || isPro === 1) &&
            isMobileVersion && (
              <View
                style={
                  lastIndex
                    ? {
                        position: "absolute",
                        width: isMobileVersion ? 260 : 336,
                        height: isMobileVersion ? 140 + 24 : 182 + 24,
                        zIndex: 999,
                        left: -100,
                        bottom: 0,
                        paddingBottom: 24,
                      }
                    : {
                        position: "absolute",
                        width: isMobileVersion ? 260 : 336,
                        height: isMobileVersion ? 140 + 24 : 182 + 24,
                        zIndex: 999,
                        left: -100,
                        top: 0,
                        paddingTop: 24,
                      }
                }>
                <View
                  style={{
                    backgroundColor: getClass("bg-gray1"),
                    width: isMobileVersion ? 260 : 336,
                    height: isMobileVersion ? 140 : 182,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingHorizontal: 22,
                    borderRadius: 18,
                    width: "100%",
                    height: "100%",
                    boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                  }}>
                  <CImage
                    source={Images.iconVerifiedBadgeMedium}
                    alt="verified account"
                    width={isMobileVersion ? 24 : 35}
                    height={isMobileVersion ? 24 : 35}
                    layout="fixed"
                  />
                  <Text
                    style={{
                      fontFamily: Fonts.primaryBold,
                      fontSize: isMobileVersion ? 16 : 20,
                      lineHeight: isMobileVersion ? 20 : 24,
                      color: Colors.white,
                      marginVertical: isMobileVersion ? 9 : 14,
                      textAlign: "center",
                    }}>
                    Official Account
                  </Text>
                  <Text
                    style={{
                      fontFamily: Fonts.primaryRegular,
                      fontSize: isMobileVersion ? 12 : 16,
                      lineHeight: isMobileVersion ? 16 : 18,
                      color: Colors.white80,
                      textAlign: "center",
                    }}>
                    This is an official account verified by{" "}
                    {process.env.NEXT_PUBLIC_APP_NAME}
                  </Text>
                </View>
              </View>
            )}
        </View>
        {dist !== null && !isMobileVersion && !isGuides && (
          <Text
            style={[
              styles.title,
              { color: getClass("text-white50") },
            ]}>{`${dist?.toFixed(1)} km`}</Text>
        )}
        {isGuides && !isMobileVersion && <Text>{infoViews()}</Text>}
      </View>
      {isGuides && !isMobileVersion ? null : infoViews()}
      {dist !== null && isMobileVersion && !isGuides && (
        <Text
          style={{
            color: getClass("text-darkGray"),
            fontSize: isMobileVersion ? 10 : 14,
            lineHeight: 18,
            fontFamily: Fonts.primaryMedium,
            textAlign: "left",
          }}>{`${dist?.toFixed(1)} km`}</Text>
      )}
      {isGuides && description !== null && (
        <ReadMoreText
          text={description}
          isMobileVersion={isMobileVersion}
          withoutButton
          isDisplayPreview
          minVisibleLines={isMobileVersion ? 1.5 : 4}
          maxLength={200}
          style={{ paddingHorizontal: 0, marginRight: 24 }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  icon: {
    width: 12,
    height: 12,
  },
  infoText: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: Fonts.primaryRegular,
    color: getClass("text-darkGray"),
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    paddingHorizontal: 10,
    // overflow: "hidden",
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    marginBottom: 11,
    alignSelf: "stretch",
    color: getClass("text-white"),
    marginRight: 24,
  },
});

export default UserInfoView;
