import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native-web";
// import VideoPlayer from 'react-native-video'
// import { VLCPlayer } from 'react-native-vlc-media-player'

const CVideoPlayer = (props) => {
  // return <VideoPlayer {...props} />
  // return <VLCPlayer {...props} videoAspectRatio="16:9" />
  return null;
};

export default CVideoPlayer;
