import React, { useEffect, useCallback, useState, useRef } from "react";
import { Colors, Constants, Fonts, Images } from "../../../config";
import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  Text,
  ScrollView,
} from "react-native-web";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../helpers";
import DotSplitedViews from "../../../components/atoms/DotSplitedViews";

const LineupStatusView = (props) => {
  const { t } = useTranslation();
  const {
    artistCount,
    headlinerCount,
    stageCount,
    isMobileVersion,
    isMatchedLineup,
  } = props;
  const artistCountTxt = formatNumber(artistCount);
  const headLinerCountTxt = formatNumber(headlinerCount);
  const stateCountTxt = formatNumber(stageCount);
  const items = [
    {
      count: artistCountTxt,
      label: t("key_lineup_artists"),
    },
    {
      count: headLinerCountTxt,
      label: isMatchedLineup ? "Match" : t("key_lineup_headliners"),
    },
  ];
  if (stageCount) {
    items.push({
      count: stateCountTxt,
      label: t("key_lineup_stages"),
    });
  }
  const renderItemView = (count, label, index) => {
    return (
      <View key={"item" + index} style={styles.rowContainer}>
        <Text
          style={[
            styles.numberStyle,
            isMobileVersion ? { fontSize: 10 } : {},
            isMatchedLineup
              ? {
                  fontSize: isMobileVersion ? 10 : 16,
                  lineHeight: isMobileVersion ? 14 : 20,
                }
              : {},
          ]}>
          {count}
        </Text>
        <Text
          style={[
            styles.textStyle,
            isMobileVersion ? { fontSize: 10 } : {},
            isMatchedLineup
              ? {
                  fontSize: isMobileVersion ? 10 : 16,
                  lineHeight: isMobileVersion ? 14 : 20,
                }
              : {},
          ]}>
          {label}
        </Text>
      </View>
    );
  };
  const renderViews = (items) => {
    return items.map((item, index) =>
      renderItemView(item.count, item.label, index)
    );
  };
  return (
    <View style={[styles.container, props.style]}>
      <DotSplitedViews
        views={renderViews(items)}
        dotStyle={styles.blackDot}
        isMobileVersion={isMobileVersion}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 50,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 20,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
  label: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 20,
    color: Colors.white,
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.white,
  },
  blackDot: {
    backgroundColor: Colors.grayText,
  },
  numberStyle: {
    fontFamily: Fonts.primaryBold,
    fontSize: 20,
    lineHeight: 24,
    marginRight: 6,
    color: Colors.white,
  },
  textStyle: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 20,
    lineHeight: 18,
    color: Colors.white,
  },
});

export default LineupStatusView;
