import React, { useEffect, useState, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import { useTranslation } from "react-i18next";
import { CAvatar, CImage } from "../atoms";
import { formatNumber } from "../../helpers";

const LineupCountView = (props) => {
  const { t } = useTranslation();
  const { avatarSize } = props;
  const _avatarSize = avatarSize || 16;
  const lineup = props.lineup;
  let headlinerCount = "";
  if (lineup && lineup.headlinerCount) {
    headlinerCount = formatNumber(lineup.headlinerCount);
  }

  const renderAvatar = (avatar, index) => {
    const avatarUrl = avatar.avatar;
    if (index == 0) {
      return (
        <CAvatar key={index + "avatar"} url={avatarUrl} size={_avatarSize} />
      );
    }
    return (
      <CAvatar
        key={index + "avatar"}
        url={avatarUrl}
        size={_avatarSize}
        style={{ marginLeft: -(_avatarSize / 2) }}
      />
    );
  };
  const renderAvatars = (avatars) => {
    return (
      <View style={[styles.rowContainer]}>
        {avatars
          .slice(0)
          .reverse()
          .map((avatar, index) => {
            return renderAvatar(avatar, index);
          })}
      </View>
    );
  };
  return (
    <View style={[styles.rowContainer, props.style]}>
      {/*renderAvatars(lineupAvatars)*/}
      <Text style={[styles.description, props.textStyle, { color: "#939393" }]}>
        {headlinerCount}
      </Text>
      <Text style={[styles.description, props.textStyle]}>
        {t("key_lineup_headliners")}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  popularityIcon: {
    width: 16,
    height: 16,
    resizeMode: "contain",
  },
  popularityIconText: {
    fontSize: 15,
    fontFamily: Fonts.primaryMedium,
    color: Colors.grayText2,
  },
  rowContainer: {
    flexDirection: "row",
  },
  description: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    color: "white",
    marginLeft: 0,
  },
});

export default LineupCountView;
