import React from "react";
import {
  Image,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native-web";
import { Colors, Constants, Fonts } from "../../../../config";
import { CImage } from "../../../atoms";
import AvatarTagItem from "./AvatarTagItem";
import Link from "next/link";
import { formatCurrency, getClass } from "../../../../helpers/formatHelpers";
const TagItem = (props) => {
  const { item, isMobileVersion } = props;
  const onPressItem = (item) => {
    if (props.onPressItem) {
      props.onPressItem(item);
    }
  };

  if (!item) return null;

  const isPicked = item.isHeadliner || item.isSelected;
  const { icon, name, avatar, categoryType } = item;
  const isArtist = categoryType == Constants.categoryType.CATEGORY_ARTIST;
  let title =
    name ||
    item.title ||
    item.label ||
    item?.minValue?.toString()?.split(".")[0];
  let subtitle = null;
  if (title == "N/A") {
    // title = "   ";
    title = "N/A";
  }
  if (title?.includes(" - ") && !item.isHeading) {
    subtitle = title.split(" - ");
  }
  if (title?.includes(" | ") && item.isHeading) {
    subtitle = title.split(" | ");
  }
  if (
    (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
      process.env.NEXT_PUBLIC_APP_NAME == "Parkful" ||
      process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") &&
    item.filter_slug == "{price-value}"
  ) {
    title = `From ${formatCurrency(item?.minValue)}`;
    // title =
    //   formatCurrency(item?.minValue) == formatCurrency(item?.maxValue)
    //     ? formatCurrency(item?.minValue)
    //     : `${formatCurrency(item?.minValue)} - ${formatCurrency(
    //         item?.maxValue
    //       )}`;
  }
  if (
    process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia" &&
    item.category == Constants.tagCategory.TAG_CATEGORY_CASTLE_SIZE
  ) {
    subtitle = [item?.title?.split(" - ")[0]?.trim()];
  }
  const isSection = item.children;
  if (isSection) {
    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          if (item.title !== "Website") {
            onPressItem(item);
          }
        }}>
        <View
          style={[
            styles.iconContainerStyle,
            isMobileVersion ? { height: 24, width: 24 } : {},
          ]}>
          {item.title === "Website" && item.value !== null ? (
            <Link href={item.value}>
              {item.contactWebsite_follow_link ? (
                <a
                  target="_blank"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <CImage
                    width={isMobileVersion ? 14 : 20}
                    height={isMobileVersion ? 14 : 20}
                    layout="fixed"
                    style={styles.icon}
                    imgUrl={icon}
                  />
                </a>
              ) : (
                <a
                  rel="nofollow"
                  target="_blank"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <CImage
                    width={isMobileVersion ? 14 : 20}
                    height={isMobileVersion ? 14 : 20}
                    layout="fixed"
                    style={styles.icon}
                    imgUrl={icon}
                  />
                </a>
              )}
            </Link>
          ) : (
            <CImage
              width={isMobileVersion ? 14 : 20}
              height={isMobileVersion ? 14 : 20}
              layout="fixed"
              style={styles.icon}
              imgUrl={icon}
            />
          )}
          {item.title && (
            <h3 className="hidden">
              {process.env.NEXT_PUBLIC_APP_NAME == "Festyful" ||
              process.env.NEXT_PUBLIC_APP_NAME == "SpaClub" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Vybeful"
                ? item.title
                : item.title
                    ?.replace("Venue_1", "Venue")
                    ?.replace("Venue_2", "Venue")}
            </h3>
          )}
        </View>
      </TouchableOpacity>
    );
  }
  const renderTagView = (isPicked, title) => {
    if (props.renderTagItemView) {
      return props.renderTagItemView({ isPicked, title });
    }
    return (
      <View
        style={[
          styles.tagItemContainer,
          isMobileVersion ? { height: 24 } : {},
        ]}>
        {isPicked && (
          <div
            className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1 rounded-full"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
        )}
        {subtitle ? (
          <View style={{ flexDirection: "row" }}>
            {(process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
              process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino") &&
              item?.isHeading &&
              !item?.isCountInfo && <h3 className="hidden">{subtitle[0]}</h3>}
            <Text
              style={[
                isPicked ? styles.selectedTagItemText : styles.tagItemText,
                isMobileVersion ? { fontSize: 10 } : {},
                item?.isHeading ? { fontFamily: Fonts.primaryMedium } : {},
                process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" &&
                item?.isCountInfo
                  ? { marginRight: 3, color: "#676767" }
                  : {},
              ]}>
              {subtitle[0]}
            </Text>
            {subtitle[1] && (
              <Text
                style={[
                  [
                    isPicked ? styles.selectedTagItemText : styles.tagItemText,
                    isMobileVersion ? { fontSize: 10 } : {},
                  ],
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" &&
                  item?.isCountInfo
                    ? {}
                    : { marginLeft: 3, color: "#676767" },
                ]}>
                {subtitle[1]}
              </Text>
            )}
          </View>
        ) : (
          <Text
            style={[
              isPicked ? styles.selectedTagItemText : styles.tagItemText,
              isMobileVersion ? { fontSize: 10 } : {},
            ]}>
            {title}
          </Text>
        )}
      </View>
    );
  };
  if (avatar || isArtist) {
    return <AvatarTagItem {...props} />;
  }
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        onPressItem(item);
      }}>
      {renderTagView(isPicked, title)}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: { alignSelf: "stretch" },
  tagItemContainer: {
    cursor: "pointer",
    flexDirection: "row",
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 5,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: getClass("border-black"),
    borderRadius: 15,
    marginRight: 6,
    overflow: "hidden",
    marginBottom: 16,
  },
  iconContainerStyle: {
    marginRight: 6,
    marginLeft: 6,
    height: 30,
    width: 30,
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: getClass("bg-icon-tag"),
  },
  tagItemText: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 18,
    color: getClass("text-white"),
  },
  selectedTagItemText: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 18,
    color: getClass("text-white"),
  },
  icon: {
    width: 14,
    height: 14,
  },
});

export default TagItem;
