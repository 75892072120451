import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native-web";
import { Fonts, Colors, Constants, Images } from "../../../../config";
import { formatDate } from "../../../../helpers";
import { CImage, DotSplitedText, ReadMoreText } from "../../../atoms";
import DotSplitedViews from "../../../atoms/DotSplitedViews";
import {
  diffFormat,
  formatCurrency,
  formatNumber,
  getClass,
} from "../../../../helpers/formatHelpers";
import UserInfoView from "./UserInfoView";
import SectionView from "./SectionView";
import moment from "moment";

const DateView = (props) => {
  const { date, isMobileVersion } = props;

  const getWeekDayMonth = (_date) => {
    const dateString = formatDate(
      _date,
      Constants.dateFormat.DATE_FORMAT_SIMPLE
    );
    if (!dateString) return { week: "", day: "", month: "" };
    const dateStringArray = dateString.split(" ");
    if (dateStringArray.length != 3) return { week: "", day: "", month: "" };
    return {
      week: dateStringArray[0].toUpperCase(),
      day: dateStringArray[1],
      month: dateStringArray[2].toUpperCase(),
    };
  };
  const { week, day, month } = getWeekDayMonth(date);
  return (
    <View style={[styles.dateContainer, isMobileVersion ? { width: 30 } : {}]}>
      <Text
        style={[
          styles.dateMonthText,
          isMobileVersion
            ? {
                fontSize: 8,
                lineHeight: 12,
              }
            : {},
        ]}>
        {week}
      </Text>
      <Text
        style={[
          styles.dateDayText,
          isMobileVersion
            ? {
                fontSize: 10,
                lineHeight: 12,
                marginVertical: 4,
              }
            : {},
        ]}>
        {day}
      </Text>
      <Text
        style={[
          styles.dateMonthText,
          isMobileVersion
            ? {
                fontSize: 8,
                lineHeight: 12,
              }
            : {},
        ]}>
        {month}
      </Text>
    </View>
  );
};

const InfoView = (props) => {
  const {
    isMobileVersion,
    isPlaylistMode,
    isChartsMode,
    lastIndex,
    firstIndex,
    isGuides,
    isHeading,
    withoutBadge,
  } = props;
  const {
    title,
    startDate,
    endDate,
    streetLocation,
    location,
    city,
    nationality,
    price,
    lineup,
    totalConnection,
    totalRelatedConnection,
    totalFollower,
    totalGoing,
    totalSaved,
    totalLineup,
    isOfficial,
    isPro,
    description,
    dist,
  } = props.item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  const time = diffFormat(startDate, endDate);

  const renderViews = () => {
    return isMobileVersion
      ? [
          <View style={[styles.rowContainer]}>
            {dist !== null && !isGuides && (
              <Text
                style={{
                  color: getClass("text-darkGray"),
                  fontSize: isMobileVersion ? 10 : 14,
                  lineHeight: 18,
                  fontFamily: Fonts.primaryMedium,
                  textAlign: "left",
                  marginRight: 6,
                }}>{`${dist?.toFixed(1)} km`}</Text>
            )}
            <View style={styles.rowContainer}>
              <CImage
                key={1}
                source={Images.iconSearchArtist}
                width={isMobileVersion ? 14 : 18}
                height={isMobileVersion ? 14 : 18}
                layout="fixed"
                alt="lineup artists"
              />
              <Text
                key={2}
                style={[
                  styles.infoText,
                  { marginLeft: 4 },
                  isMobileVersion ? { fontSize: 10 } : {},
                ]}>
                {formatNumber(totalLineup || lineup?.totalLineup || 0)}
              </Text>
            </View>
          </View>,
        ]
      : [
          <View style={[styles.rowContainer]}>
            <View style={[styles.rowContainer, { marginLeft: 10 }]}>
              <CImage
                source={Images.iconSearchArtist}
                width={18}
                height={18}
                layout="fixed"
                alt="lineup artists"
              />
              <Text style={[styles.infoText, { marginLeft: 4 }]}>
                {formatNumber(totalLineup || lineup?.totalLineup || 0)}
              </Text>
            </View>
          </View>,
        ];
  };
  return (
    <View
      style={[styles.infoContainer, { marginLeft: isMobileVersion ? 4 : 20 }]}>
      <View
        style={[
          {
            marginBottom: isGuides && isMobileVersion ? 0 : 10,
            marginRight: 24,
            justifyContent: "space-between",
            alignItems: "center",
          },
          isGuides && isMobileVersion
            ? { alignItems: "flex-start" }
            : styles.rowContainer,
        ]}
        onMouseOver={() => setShowPopupOfficial(true)}
        onMouseLeave={() => setShowPopupOfficial(false)}>
        <Text
          ellipsizeMode={"tail"}
          numberOfLines={1}
          style={[
            styles.title,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 16,
                }
              : {},
            isPlaylistMode || isChartsMode
              ? { marginRight: 60, maxWidth: "75%" }
              : {},
            { marginBottom: 0, marginRight: 0 },
          ]}>
          {isGuides || isHeading ? <h2>{title}</h2> : title}
        </Text>
        {(isOfficial === 1 || isPro === 1) && !withoutBadge && (
          <View
            style={{
              marginLeft: 6,
              marginTop: 0,
              position: "relative",
            }}>
            <CImage
              source={Images.iconVerifiedBadge}
              alt="badge official"
              width={14}
              height={14}
              layout="fixed"
            />

            {showPopupOfficial &&
              (isOfficial === 1 || isPro === 1) &&
              !isMobileVersion && (
                <View
                  style={{
                    position: "absolute",
                    width: isMobileVersion ? 260 + 24 : 336 + 24,
                    height: isMobileVersion ? 140 : 182,
                    zIndex: 999,
                    left: 0,
                    top: firstIndex ? -20 : lastIndex ? -110 : -91,
                    paddingLeft: 24,
                  }}>
                  <View
                    style={{
                      backgroundColor: getClass("bg-gray1"),
                      width: isMobileVersion ? 260 : 336,
                      height: isMobileVersion ? 140 : 182,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingHorizontal: 22,
                      borderRadius: 18,
                      width: "100%",
                      height: "100%",
                      boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                    }}>
                    <CImage
                      source={Images.iconVerifiedBadgeMedium}
                      alt="verified account"
                      width={isMobileVersion ? 24 : 35}
                      height={isMobileVersion ? 24 : 35}
                      layout="fixed"
                    />
                    <Text
                      style={{
                        fontFamily: Fonts.primaryBold,
                        fontSize: isMobileVersion ? 16 : 20,
                        lineHeight: isMobileVersion ? 20 : 24,
                        color: Colors.white,
                        marginVertical: isMobileVersion ? 9 : 14,
                        textAlign: "center",
                      }}>
                      Official Account
                    </Text>
                    <Text
                      style={{
                        fontFamily: Fonts.primaryRegular,
                        fontSize: isMobileVersion ? 12 : 16,
                        lineHeight: isMobileVersion ? 16 : 18,
                        color: Colors.white80,
                        textAlign: "center",
                      }}>
                      This is an official account verified by{" "}
                      {process.env.NEXT_PUBLIC_APP_NAME}
                    </Text>
                  </View>
                </View>
              )}
          </View>
        )}
        {dist !== null && !isMobileVersion && !isGuides && (
          <Text
            style={[
              styles.title,
              { color: getClass("text-white50") },
            ]}>{`${dist?.toFixed(1)} km`}</Text>
        )}
        {isGuides && (
          <View style={styles.rowContainer}>
            <DotSplitedText
              texts={[
                time,
                (streetLocation || city || location) +
                  (nationality ? ", " + nationality : ""),
              ]}
              style={{ marginBottom: 0 }}
              textStyle={{
                color: Colors.darkGray,
                fontSize: isMobileVersion ? 9 : 14,
              }}
              isMobileVersion={isMobileVersion}
            />

            <DotSplitedViews
              views={renderViews()}
              style={
                isMobileVersion
                  ? { marginLeft: 6, marginTop: 0 }
                  : { marginLeft: 0 }
              }
              isMobileVersion={isMobileVersion}
            />
          </View>
        )}
      </View>
      {!isGuides && (
        <View style={isMobileVersion ? {} : styles.rowContainer}>
          <DotSplitedText
            texts={[
              time,
              (streetLocation || city || location) +
                (nationality ? ", " + nationality : ""),
            ]}
            style={{ marginBottom: 0 }}
            textStyle={{
              color: Colors.darkGray,
              fontSize: isMobileVersion ? 9 : 14,
            }}
            isMobileVersion={isMobileVersion}
          />

          <DotSplitedViews
            views={renderViews()}
            style={
              isMobileVersion
                ? { marginLeft: 0, marginTop: 10 }
                : { marginLeft: 0 }
            }
            isMobileVersion={isMobileVersion}
          />
        </View>
      )}
      {isGuides && description !== null && (
        <ReadMoreText
          text={description}
          isMobileVersion={isMobileVersion}
          withoutButton
          isDisplayPreview
          minVisibleLines={isMobileVersion ? 1.5 : 4}
          maxLength={200}
          style={{ paddingHorizontal: 0, marginRight: 24 }}
        />
      )}
    </View>
  );
};

const LandscapeCardItem = (props) => {
  const {
    general,
    marginBottom,
    isMobileVersion,
    isPlaylistMode,
    isChartsMode,
    isGuides,
    isHeading,
    chartIndex,
    firstIndex,
    lastIndex,
    withoutBadge,
  } = props;
  const { item, category, showYear } = props;
  const { avatar, lineup, totalLineup } = item;

  const renderYear = () => {
    if (showYear) {
      const yearString = formatDate(item.startDate, "YYYY");
      return (
        <SectionView
          section={{ title: yearString }}
          isMobileVersion={isMobileVersion}
        />
      );
    }
    return null;
  };
  const renderInfoView = () => {
    if (
      category === Constants.categoryType.CATEGORY_ARTIST ||
      category === Constants.categoryType.CATEGORY_PROMOTERS ||
      category === Constants.categoryType.CATEGORY_VENUE ||
      category === Constants.categoryType.CATEGORY_ROOFTOP ||
      category === Constants.categoryType.CATEGORY_SPA ||
      category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
      category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
      category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
      category === Constants.categoryType.CATEGORY_ZOO ||
      category === Constants.categoryType.CATEGORY_ANIMAL ||
      category === Constants.categoryType.CATEGORY_PARKFUL ||
      category === Constants.categoryType.CATEGORY_CASINO ||
      category === Constants.categoryType.CATEGORY_CASTLE ||
      category === Constants.categoryType.CATEGORY_RESORT ||
      category === Constants.categoryType.CATEGORY_APARTHOTEL ||
      category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
      category === Constants.categoryType.CATEGORY_OTHER
    ) {
      return (
        <UserInfoView
          categoryType={category}
          item={item}
          isMobileVersion={isMobileVersion}
          firstIndex={firstIndex}
          lastIndex={lastIndex}
          isGuides={isGuides}
          isHeading={isHeading}
          withoutBadge={withoutBadge}
        />
      );
    }

    return (
      <InfoView
        style={{ flex: 1 }}
        item={item}
        isMobileVersion={isMobileVersion}
        isPlaylistMode={isPlaylistMode}
        isChartsMode={isChartsMode}
        firstIndex={firstIndex}
        lastIndex={lastIndex}
        isGuides={isGuides}
        isHeading={isHeading}
        withoutBadge={withoutBadge}
      />
    );
  };
  return (
    <View style={{ alignSelf: "stretch" }}>
      {renderYear()}
      <View
        style={[
          styles.container,
          isMobileVersion ? { borderRadius: 0, marginBottom: 7 } : {},
          showYear && { marginBottom: 0 },
          { marginBottom: marginBottom !== null ? marginBottom : 12 },
          isGuides && !isMobileVersion
            ? {
                height:
                  process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
                    ? 172
                    : 170,
              }
            : {},
        ]}
        // onPress={() => {
        //   onItemAction({
        //     type: Constants.actionType.ACTION_VIEW,
        //     item: props.item,
        //   });
        // }}
      >
        {category !== Constants.categoryType.CATEGORY_ARTIST &&
          category !== Constants.categoryType.CATEGORY_PROMOTERS &&
          category !== Constants.categoryType.CATEGORY_OTHER &&
          category !== Constants.categoryType.CATEGORY_VENUE &&
          category !== Constants.categoryType.CATEGORY_ROOFTOP &&
          category !== Constants.categoryType.CATEGORY_SPA &&
          category !== Constants.categoryType.CATEGORY_VENUE_BEACHFUL &&
          category !== Constants.categoryType.CATEGORY_PREFAB_COMPANY &&
          category !== Constants.categoryType.CATEGORY_PREFAB_HOMES &&
          category !== Constants.categoryType.CATEGORY_ZOO &&
          category !== Constants.categoryType.CATEGORY_ANIMAL &&
          category !== Constants.categoryType.CATEGORY_PARKFUL &&
          category !== Constants.categoryType.CATEGORY_CASINO &&
          category !== Constants.categoryType.CATEGORY_CASTLE &&
          category !== Constants.categoryType.CATEGORY_RESORT &&
          category !== Constants.categoryType.CATEGORY_APARTHOTEL &&
          category !== Constants.categoryType.CATEGORY_VENUE_COCKTAIL && (
            <DateView date={item.startDate} isMobileVersion={isMobileVersion} />
          )}

        {category === Constants.categoryType.CATEGORY_OTHER ||
        category === Constants.categoryType.CATEGORY_ARTIST ? (
          <View
            style={[
              styles.imageContainer,
              isMobileVersion
                ? { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }
                : {},
            ]}>
            <CImage
              imgUrl={
                avatar ||
                (category === Constants.categoryType.CATEGORY_OTHER
                  ? "Local:defaultOther"
                  : "Local:defaultArtist")
              }
              width={100}
              height={100}
              layout="fixed"
              alt={`Avatar ${props?.item?.displayName}`}
            />
          </View>
        ) : (
          <View
            style={[
              styles.imageContainer,
              category === Constants.categoryType.CATEGORY_FESTIVAL ||
              category === Constants.categoryType.CATEGORY_EVENT ||
              isMobileVersion
                ? { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }
                : {},
              isGuides && !isMobileVersion
                ? {
                    width:
                      category === Constants.categoryType.CATEGORY_FESTIVAL ||
                      category === Constants.categoryType.CATEGORY_EVENT
                        ? 170
                        : 250,
                    height: 170,
                  }
                : {},
            ]}>
            <CImage
              imgUrl={
                avatar
                  ? avatar
                  : category === Constants.categoryType.CATEGORY_ARTIST
                  ? "Local:defaultArtist"
                  : category === Constants.categoryType.CATEGORY_FESTIVAL
                  ? "Local:defaultFestival"
                  : category === Constants.categoryType.CATEGORY_EVENT
                  ? "Local:defaultEvent"
                  : category === Constants.categoryType.CATEGORY_VENUE
                  ? "Local:defaultVenue"
                  : category === Constants.categoryType.CATEGORY_ROOFTOP
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to rooftop icon
                  : category === Constants.categoryType.CATEGORY_SPA
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to spa icon
                  : category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to venue beachful icon
                  : category === Constants.categoryType.CATEGORY_PREFAB_COMPANY
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to prefab company icon
                  : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to prefab home icon
                  : category === Constants.categoryType.CATEGORY_ZOO
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to zoo icon
                  : category === Constants.categoryType.CATEGORY_ANIMAL
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to animal icon
                  : category === Constants.categoryType.CATEGORY_PARKFUL
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to parkful icon
                  : category === Constants.categoryType.CATEGORY_CASINO
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to casino icon
                  : category === Constants.categoryType.CATEGORY_CASTLE
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to castle icon
                  : category === Constants.categoryType.CATEGORY_RESORT
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to resort icon
                  : category === Constants.categoryType.CATEGORY_APARTHOTEL
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to aparthotel icon
                  : category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                  ? "Local:defaultGeneral" //TODO (Garry): Need change to venue cocktail icon
                  : "Local:defaultOther"
              }
              style={styles.imageView}
              resizeMode="cover"
              width={
                isGuides && !isMobileVersion
                  ? category === Constants.categoryType.CATEGORY_FESTIVAL ||
                    category === Constants.categoryType.CATEGORY_EVENT
                    ? 170
                    : 250
                  : 100
              }
              height={isGuides && !isMobileVersion ? 170 : 100}
              layout="fixed"
              alt={
                category === Constants.categoryType.CATEGORY_VENUE ||
                category === Constants.categoryType.CATEGORY_ROOFTOP ||
                category === Constants.categoryType.CATEGORY_SPA ||
                category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
                category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
                category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
                category === Constants.categoryType.CATEGORY_ZOO ||
                category === Constants.categoryType.CATEGORY_ANIMAL ||
                category === Constants.categoryType.CATEGORY_PARKFUL ||
                category === Constants.categoryType.CATEGORY_CASINO ||
                category === Constants.categoryType.CATEGORY_CASTLE ||
                category === Constants.categoryType.CATEGORY_RESORT ||
                category === Constants.categoryType.CATEGORY_APARTHOTEL ||
                category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                  ? `${props?.item?.displayName} Logo`
                  : `${props?.item?.title} ${moment(
                      props?.item?.startDate
                    ).format("YYYY")} Logo`
              }
            />
          </View>
        )}
        {renderInfoView()}
        {isChartsMode && (
          <View
            style={[
              styles.infoBox,
              isMobileVersion
                ? { width: 40, height: 16, top: 12, right: 16 }
                : {},
            ]}>
            <div
              className="bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              }}
            />
            <Text
              style={[
                styles.infoBoxText,
                { marginLeft: 0 },
                isMobileVersion ? { fontSize: 10, lineHeight: 10 } : {},
              ]}>
              {chartIndex + 1}
            </Text>
          </View>
        )}
        {/* <ActiveButton
          style={styles.plusButton}
          plusIcon={styles.plusIcon}
          isActive={
            (props.category === Constants.categoryType.CATEGORY_FESTIVAL
              ? general?.festivalGoingList?.includes(props.item.profileId) ||
                general?.festivalSaveList?.includes(props.item.profileId)
              : false) ||
            (props.category === Constants.categoryType.CATEGORY_ARTIST
              ? general?.artistFollowList?.includes(props.item.profileId)
              : false) ||
            (props.category === Constants.categoryType.CATEGORY_OTHER ||
            props.category === Constants.categoryType.CATEGORY_USER
              ? general?.userFollowList?.includes(
                  props.item.profileId || props.item.id
                )
              : false)
            // props.item.isActive ||
            // props.item.isGoing ||
            // props.item.isSaving ||
            // props.item.isConnect ||
            // props.item.isFollow
          }
          onPress={() =>
            onItemAction({
              type: Constants.actionType.ACTION_PLUS,
              item: props.item,
            })
          }
        /> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    backgroundColor: getClass("bg-tab-gray1"),
    ...getClass("border-card-landscape"),
    alignSelf: "stretch",
    flexDirection: "row",
    marginBottom: 12,
    borderRadius: 16,
    // overflow: "hidden",
  },
  dateContainer: {
    marginHorizontal: 5,
    width: 56,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
  },
  dateMonthText: {
    fontSize: 12,
    lineHeight: 12,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white40,
  },
  dateDayText: {
    fontSize: 14,
    lineHeight: 15,
    textAlign: "center",
    marginVertical: 10,
    color: Colors.white,
  },
  imageContainer: {
    width: 100,
    height: 100,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    overflow: "hidden",
  },
  imageView: {
    width: "100%",
    height: "100%",
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    paddingHorizontal: 10,
    // overflow: "hidden",
  },
  title: {
    fontFamily: Fonts.primaryBold,
    fontSize: 18,
    lineHeight: 22,
    marginBottom: 11,
    alignSelf: "stretch",
    color: Colors.white,
    marginRight: 36,
    textAlign: "left",
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: 12,
    height: 12,
  },
  infoText: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: Fonts.primaryRegular,
    color: Colors.darkGray,
  },
  yearText: {
    fontSize: 18,
    lineHeight: 20,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
  infoBox: {
    position: "absolute",
    right: 56,
    top: 34,
    width: 100,
    height: 32,
    borderRadius: 60,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  infoBoxText: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    position: "relative",
    zIndex: 10,
    marginLeft: 8,
  },
  plusButton: {
    position: "absolute",
    right: 16,
    top: 12,
    width: 24,
    height: 24,
  },
  plusIcon: {
    width: 7.68,
    height: 7.68,
  },
});

export default LandscapeCardItem;
