import React, { Component } from "react";
import { StyleSheet, View } from "react-native-web";
import orderBy from "lodash/orderBy";
import TagItem from "./components/TagItem";

export class TagIconSectionList extends Component {
  constructor(props) {
    super(props);
  }
  onPressItem = (item) => {
    if (this.props.onPressItem) {
      this.props.onPressItem(item);
    }
  };
  renderTagItem = (item, index) => {
    if (this.props.renderTagItem) {
      return this.props.renderTagItem();
    }
    if (!item) return null;
    return (
      <TagItem
        item={item}
        key={index + "tag"}
        onPressItem={this.onPressItem}
        isMobileVersion={this.props.isMobileVersion}
      />
    );
  };
  renderTags = (items) => {
    if (!items) return null;
    return this.renderTagsInView(items);
  };
  renderTagsInView = (items) => {
    return items.map((item, index) => this.renderTagItem(item, index));
  };
  renderSections = (sections) => {
    const tags = [];
    if (!sections) return null;
    sections.forEach((section) => {
      tags.push(section);
      if (section.children) {
        tags.push(...section.children);
      }
    });
    return this.renderTags(tags);
  };
  render() {
    return (
      <View style={[styles.tagContainer, this.props.style]}>
        {this.renderSections(orderBy(this.props.items, "order"))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tagContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    paddingRight: 20,
  },
});

export default TagIconSectionList;
