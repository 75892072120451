import React from "react";
import { CModal } from "../../atoms";
import MultipleCheckContainer from "./MultipleCheckContainer";

const MultipleCheckModal = React.forwardRef((props, ref) => {
  return (
    <CModal ref={ref} {...props}>
      <MultipleCheckContainer
        {...props}
        style={[{ marginTop: 14 }, props.isDate ? { minHeight: 430 } : {}]}
      />
    </CModal>
  );
});
export default MultipleCheckModal;
