import React from "react";
import { CModal } from "../../atoms";
import VerificationCodeContainer from "./containers/VerificationCodeContainer";

const VerificationCodeModal = React.forwardRef((props, ref) => {
  return (
    <CModal ref={ref} {...props}>
      <VerificationCodeContainer {...props} />
    </CModal>
  );
});
export default VerificationCodeModal;
