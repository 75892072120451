import React from "react";
import PortraitCardItem from "./PortraitCardItem";
import LandscapeCardItem from "./LandscapeCardItem";
const CardItem = (props) => {
  if (props.portrait) {
    return <PortraitCardItem {...props} />;
  }
  return <LandscapeCardItem {...props} />;
};

export default CardItem;
