import React, { useEffect, useState, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import { useTranslation } from "react-i18next";

const DotSplitedViews = (props) => {
  const { t } = useTranslation();
  const { views, dotStyle, textStyle, isMobileVersion } = props;
  const renderView = (view, index, isLast) => {
    return [
      view,
      !isLast && (
        <View
          key={index + "dot"}
          style={[
            styles.dot,
            isMobileVersion ? { height: 2, width: 2 } : {},
            dotStyle,
          ]}
        />
      ),
    ];
  };
  const renderViews = (views) => {
    return views.map((text, index) => {
      const isLast = index + 1 == views.length;
      return renderView(text, index, isLast);
    });
  };
  return (
    <View style={[styles.container, props.style]}>{renderViews(views)}</View>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  dot: {
    height: 5,
    width: 5,
    borderRadius: 60,
    marginHorizontal: 10,
    backgroundColor: Colors.lightGray,
  },
});

export default DotSplitedViews;
