import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../config";
import BottomActionItem from "./BottomActionItem";

const BottomActionBar = (props) => {
  const { items } = props;
  const renderItems = () => {
    if (!items) return null;
    return items.map((item, index) => {
      return (
        <BottomActionItem
          item={item}
          key={index + "action-item"}
          onPressItem={props.onPressItem}
        />
      );
    });
  };
  return <View style={styles.container}>{renderItems()}</View>;
};

const styles = StyleSheet.create({
  container: {
    height: 52,
    alignSelf: "stretch",
    backgroundColor: Colors.grayBackground,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    marginTop: -25,
    flexDirection: "row",
  },
  menuItemContainer: {
    flex: 1,
  },
});

export default BottomActionBar;
