import React, { Component } from "react";
import {
  StyleSheet,
  Image,
  View,
  TouchableWithoutFeedback,
  Text,
  FlatList,
} from "react-native-web";
import TagList from ".";
import { Colors, Fonts } from "../../../config";

const TagSection = (props) => {
  const { isMobileVersion } = props;
  return (
    <View
      style={{
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        height: isMobileVersion ? 24 : 40,
        paddingLeft: 1,
        paddingRight: 1,
        borderRadius: 20,
        overflow: "hidden",
        marginBottom: 16,
        marginTop: isMobileVersion ? 0 : 20,
        marginTop: 20,
      }}>
      <div
        className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          justifyContent: "center",
          alignItems: "center",
        }}></div>
      <View
        style={{
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          height: isMobileVersion ? 22 : 38,
          paddingLeft: 29,
          paddingRight: 29,
          borderRadius: 20,
          backgroundColor: Colors.black,
          overflow: "hidden",
        }}>
        <Text
          style={{
            fontFamily: Fonts.primaryBold,
            fontSize: isMobileVersion ? 12 : 18,
            lineHeight: isMobileVersion ? 12 : 32,
            color: "white",
            marginBottom: 32,
            marginTop: 32,
          }}>
          {props.title}
        </Text>
        <Text
          style={{
            fontFamily: Fonts.primaryBold,
            fontSize: isMobileVersion ? 12 : 18,
            lineHeight: isMobileVersion ? 12 : 32,
            color: Colors.grayText2,
            marginBottom: 32,
            marginTop: 32,
            marginLeft: 10,
          }}>
          {props.suffix}
        </Text>
      </View>
    </View>
  );
};
export class TagSectionList extends Component {
  constructor(props) {
    super(props);
  }
  onPressItem = (item) => {
    if (this.props.onPressItem) {
      this.props.onPressItem(item);
    }
  };
  renderSections = (sections) => {
    const tagViews = [];
    if (!sections) return null;
    sections.forEach((section, index) => {
      tagViews.push(
        <TagSection
          key={"section" + index}
          title={section.title}
          suffix={section.suffix}
          isMobileVersion={this.props.isMobileVersion}
        />
      );
      if (section.data) {
        section.data.map((listData) => {
          tagViews.push(
            <TagList
              renderTagItemView={this.props.renderTagItemView}
              key={"tag" + index}
              items={listData.list}
              onPressItem={this.onPressItem}
              style={this.props.tagListContainerStyle}
              isMobileVersion={this.props.isMobileVersion}
            />
          );
        });
      }
    });
    return tagViews;
  };
  render() {
    return (
      <View style={[styles.tagContainer, this.props.style]}>
        {this.renderSections(this.props.items)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tagContainer: {
    alignSelf: "stretch",
    flexDirection: "column",
  },
});

export default TagSectionList;
