import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native-web";
import { Colors, Fonts } from "../../../../config";
import { IconizedTextInput, IconizedButton } from "../../../atoms";
import { useTranslation } from "react-i18next";

const VerificationCodeContainer = (props) => {
  const { description, onClose, onResend } = props;
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const onSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(code);
    }
  };
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{description}</Text>
      </View>
      <IconizedTextInput
        placeholder={t("key_placeholder_verification_code")}
        bordered
        onChangeText={(text) => {
          setCode(text);
        }}
        value={code}
        maxLength={6}
        autoCapitalize="none"
        autoCorrect={false}
        containerStyle={{}}
        keyboardType="numeric"
      />
      <View style={styles.infoContainer}>
        <Text style={styles.info}>
          {t("key_label_resend")}{" "}
          <Text style={styles.infoBold} onPress={onResend}>
            {t("key_action_resend")}
          </Text>
        </Text>
      </View>
      <View style={styles.footerButton}>
        <View style={styles.footerItem}>
          <IconizedButton
            buttonTitle={t("key_button_cancel")}
            bordered
            onPress={onClose}
          />
        </View>
        <View style={styles.footerItem}>
          <IconizedButton
            buttonTitle={t("key_button_confirm")}
            filled
            onPress={onSubmit}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  itemStyle: {
    marginBottom: 14,
  },
  titleContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: Colors.white50,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
    textAlign: "center",
  },
  infoContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    color: Colors.white50,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
    textAlign: "center",
  },
  infoBold: {
    color: Colors.white,
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 20,
    textAlign: "center",
  },
  menuItemContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: 1,
    borderTopColor: Colors.tabBottomColor,
  },
  menuItemText: {
    color: Colors.white,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
    textAlign: "center",
  },
  footerButton: {
    borderTopWidth: 1,
    borderTopColor: Colors.grayBackground,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingTop: 16,
    marginTop: 16,
    marginHorizontal: -16,
  },
  footerItem: {
    flex: 1,
    paddingHorizontal: 8,
  },
});

export default VerificationCodeContainer;
