import React, { Component } from "react";
import {
  StyleSheet,
  Image,
  View,
  TouchableWithoutFeedback,
  Text,
  FlatList,
} from "react-native-web";
import Link from "next/link";
import { Values, Colors, Fonts, Constants } from "../../../config";
import TagItem from "./components/TagItem";
import { generateURLSlug } from "../../../../helpers";

export class TagList extends Component {
  constructor(props) {
    super(props);
  }
  onPressItem = (item) => {
    if (this.props.onPressItem) {
      this.props.onPressItem(item);
    }
  };
  renderTagItem = (item, index) => {
    if (!item) return null;
    return (
      <Link
        prefetch={false}
        href={generateURLSlug(
          item.id,
          item.displayName,
          item.startDate,
          Constants.categoryType.CATEGORY_ARTIST,
          "about"
        )}>
        <a style={{ maxWidth: "100%" }}>
          <TagItem
            item={item}
            key={index + "tag"}
            onPressItem={this.onPressItem}
            renderTagItemView={this.props.renderTagItemView}
            isMobileVersion={this.props.isMobileVersion}
          />
        </a>
      </Link>
    );
  };
  renderTags = (items) => {
    if (!items) return null;
    return this.renderTagsInView(items);
  };
  renderTagsInView = (items) => {
    return items.map((item, index) => this.renderTagItem(item, index));
  };
  render() {
    return (
      <View style={[styles.tagContainer, this.props.style]}>
        {this.renderTags(this.props.items)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tagContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

export default TagList;
