import React from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Image,
  TouchableOpacity,
  Text,
} from "react-native-web";
import { Fonts, Colors, Constants } from "../../../config";
import { useTranslation } from "react-i18next";

const AlbumInfoView = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  if (!props.item) return null;
  const { name, totalCount } = props.item;
  const countPhotoString = `${t(totalCount)} media`;
  return (
    <View style={[styles.container, props.style]}>
      <Text
        style={[
          styles.title,
          isMobileVersion ? { fontSize: 12, lineHeight: 12 } : {},
        ]}>
        {name}
      </Text>
      <Text
        style={[
          styles.description,
          isMobileVersion
            ? {
                fontSize: 10,
                lineHeight: 12.19,
              }
            : {},
        ]}>
        {countPhotoString}
      </Text>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
  description: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: Fonts.primaryRegular,
    color: Colors.placeholder,
    marginTop: 10,
    marginBottom: 15,
  },
});
export default AlbumInfoView;
