import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native-web";
import { useTranslation } from "react-i18next";
import { CImage } from "../../atoms";
import { Colors } from "../../../config";

const BottomActionItem = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  if (!item) return null;
  const { disabled, icon } = item;

  return (
    <TouchableOpacity
      style={[styles.container, props.style]}
      onPress={() => {
        if (props.onPressItem) {
          props.onPressItem(item);
        }
      }}
      disabled={disabled}
    >
      {icon && (
        <CImage
          style={styles.icon}
          source={icon}
          tintColor={disabled ? Colors.white20 : null}
          resizeMode="contain"
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 20,
    height: 20,
  },
});

export default BottomActionItem;
