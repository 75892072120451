import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  SectionList,
} from "react-native-web";
import Link from "next/link";

import GridCardItem from "./components/GridCardItem";
import SectionView from "../CardList/components/SectionView";
import { generateURLSlug } from "../../../../helpers";

const CardSectionGrid = (props) => {
  const { items, hasMask, isMobileVersion } = props;

  const onLoadMore = () => {
    if (props.onLoadMore) {
      props.onLoadMore();
    }
  };
  const onRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh();
    }
  };
  const renderItem = ({ item, index }) => {
    if (!item) return null;
    return (
      <Link
        prefetch={false}
        href={generateURLSlug(
          item.id,
          item.title || item.displayName || item.nickname,
          item.startDate,
          props.category
        )}>
        <a>
          <GridCardItem
            item={item}
            index={index}
            hasMask={hasMask}
            onItemAction={props.onItemAction}
            renderInfoView={props.renderInfoView}
            category={props.category}
            isMobileVersion={isMobileVersion}
          />
        </a>
      </Link>
    );
  };
  const renderSection = ({ item, index }) => {
    return (
      <FlatList
        key={index + "list"}
        data={item.list}
        numColumns={isMobileVersion ? 3 : 6}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  };

  const renderSectionItem = ({ section }) => {
    if (props.renderSectionItem) {
      return props.renderSectionItem(section);
    }
    return <SectionView section={section} isMobileVersion={isMobileVersion} />;
  };
  return (
    <View style={[styles.container, props.style]}>
      <SectionList
        numColumns={6}
        sections={items}
        renderItem={renderSection}
        renderSectionHeader={renderSectionItem}
        refreshing={props.isLoading || false}
        keyExtractor={(item, index) => index.toString()}
        onRefresh={onRefresh}
        onEndReachedThreshold={0.4}
        onEndReached={onLoadMore}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export default CardSectionGrid;
