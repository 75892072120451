import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts, Values } from "../../../../config";
import { CImage } from "../../../../components/atoms";
import FullScreenImageModal from "./FullScreenImageModal";
import { useState } from "react";

const ImagePreview = (props) => {
  const [isShowModal, setIsShowModal] = useState(false);
  if (!props.item) return null;
  const { isFull, isAutoHeight, height, width } = props;
  const { image } = props.item;
  return (
    <>
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          setIsShowModal(true);
        }}
        style={[styles.container, props.style]}>
        <CImage
          imgUrl={image}
          width={width}
          height={height}
          layout="fixed"
          style={{ flex: 1 }}
          isAutoHeight={isAutoHeight}
          objectFit={isFull ? "contain" : "cover"}
        />
        <FullScreenImageModal
          image={image}
          isVisible={isShowModal}
          closeModal={() => {
            setIsShowModal(false);
          }}
        />
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.black,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ImagePreview;
