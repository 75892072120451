import React from "react";
import {
  Image,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  View,
} from "react-native-web";
import { Colors, Fonts } from "../../../../config";
import { CImage } from "../../../atoms";
const AvatarTagItem = (props) => {
  const { item, isMobileVersion } = props;
  const onPressItem = (item) => {
    if (props.onPressItem) {
      props.onPressItem(item);
    }
  };
  if (!item) return null;
  const { avatar, name, isHeadliner } = item;
  const title = name;
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        onPressItem(item);
      }}>
      <View
        style={[
          styles.tagItemContainer,
          isMobileVersion ? { height: 24 } : {},
        ]}>
        {isHeadliner && (
          <div
            className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
            }}
          />
        )}
        <View
          style={[
            styles.iconContainerStyle,
            isMobileVersion ? { width: 22, height: 22 } : {},
          ]}>
          <CImage imgUrl={avatar} style={{ width: "100%", height: "100%" }} />
        </View>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            isHeadliner ? styles.selectedTagItemText : styles.tagItemText,
            isMobileVersion ? { fontSize: 12 } : {},
          ]}>
          {title}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: { alignSelf: "stretch" },
  tagItemContainer: {
    cursor: "pointer",
    flexDirection: "row",
    height: 42,
    backgroundColor: Colors.grayBackground,
    borderRadius: 20,
    marginRight: 10,
    overflow: "hidden",
    marginBottom: 14,
    alignItems: "center",
  },
  iconContainerStyle: {
    margin: 1,
    height: 40,
    width: 40,
    borderRadius: 20,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white10,
  },
  tagItemText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    color: Colors.white,
    marginRight: 15,
    marginLeft: 10,
  },
  selectedTagItemText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    color: Colors.white,
    marginRight: 15,
    marginLeft: 10,
  },
  icon: {
    width: 14,
    height: 14,
  },
});

export default AvatarTagItem;
