import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Linking,
  Alert,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../../config";
import { CImage } from "../../../../components/atoms";
import CVideoPlayer from "../../../atoms/CVideoPlayer";
const VideoPreview = (props) => {
  const [isOpenMedia, setIsOpenMedia] = useState(false);
  if (!props.item) return null;
  const { isFull, isPlay } = props;
  const { image, isLink } = props.item;
  const videoUrl = props.item.video;
  const isYoutubeUrl = videoUrl != null && videoUrl.includes("www.youtube.com");
  const isFacebookUrl =
    videoUrl != null && videoUrl.includes("www.facebook.com");
  const toggleMediaModal = () => {
    if (!isOpenMedia && isLink) {
      onOpenExternalLink(videoUrl);
    } else {
      setIsOpenMedia(!isOpenMedia);
    }
  };
  const onOpenExternalLink = async (url) => {
    const win = window.open(url, "_blank");
    win.focus();
  };
  let isPlayVideo = isPlay || isOpenMedia;
  if (isYoutubeUrl || isFacebookUrl) {
    isPlayVideo = false;
  }

  const renderVideoPlayer = (_videoUrl) => {
    return (
      <CVideoPlayer
        source={{ uri: _videoUrl }}
        style={styles.thumbImage}
        useTextureView={false}
        playInBackground={true}
        disableFocus={true}
        controls={false}
      />
    );
  };
  return (
    <View activeOpacity={1} style={[styles.container, props.style]}>
      {isPlayVideo ? (
        renderVideoPlayer(videoUrl)
      ) : (
        <CImage
          imgUrl={image}
          style={styles.thumbImage}
          resizeMode={isFull ? "contain" : "cover"}
        />
      )}
      {!isPlayVideo && (
        <TouchableOpacity
          onPress={() => {
            toggleMediaModal();
          }}>
          <Image
            source={Images.iconPlayButton}
            style={styles.playIcon}
            resizeMode="contain"
            alt="icon play"
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "black",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
  },
  thumbImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  playIcon: {
    width: 40,
    height: 40,
  },
});

export default VideoPreview;
