import React, { Component, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  RefreshControl,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native-web";
import Image from "next/image";
import InfiniteScroll from "react-infinite-scroll-component";
import Link from "next/link";
import { Colors, Constants, Fonts, Values } from "../../../config";
import CardItem from "./components/CardItem";
import { generateURLSlug } from "../../../../helpers";
import TagList from "../TagList";
import LineupStatusView from "../../../modules/lineup/components/LineupStatusView";
import { ArtistDao } from "../../../DAO";
const WrappedCardList = React.forwardRef((wrapperProps, ref) => {
  const CardList = (props) => {
    const [showSelectedItem, setShowSelectedItem] = useState(null);
    const [artistSelectedItem, setArtistSelectedItem] = useState([]);
    const [isLoadingLineup, setIsLoadingLineup] = useState(false);

    const findFestivalLineup = async () => {
      setIsLoadingLineup(true);

      try {
        const artists = await new Promise((resolve, reject) => {
          ArtistDao.findWeb({
            pageSize: 999,
            eventId: showSelectedItem,
          })
            .then((result) => resolve(result))
            .catch((err) => reject(err));
        });
        const newData = artists?.data?.map((item) => ({
          ...item,
          name: item.displayName,
          isMatch: props.playlistArtist?.find((i) => i.id === item.id),
        }));
        setArtistSelectedItem(
          // newData.sort((a, b) => b.isHeadliner - a.isHeadliner)
          newData.filter((i) => i.isMatch)
        );
        setIsLoadingLineup(false);
      } catch (err) {
        console.error(err);
        setIsLoadingLineup(false);
      }
    };

    useEffect(() => {
      if (showSelectedItem) {
        findFestivalLineup();
      }
    }, [showSelectedItem]);

    const onItemAction = (data) => {
      if (props.onItemAction) {
        props.onItemAction(data);
      }
    };
    const renderItem = (item, index) => {
      if (
        !item ||
        (props.isChartsMode && parseInt(props.currentPageIndex) + index >= 100)
      )
        return null;
      return (
        <View>
          <Link
            prefetch={false}
            href={generateURLSlug(
              item.id,
              item.title || item.displayName || item.nickname,
              item.startDate,
              props.category,
              "about"
            )}>
            <a target="_blank">
              <CardItem
                key={"tab" + index}
                item={item}
                showYear={props.showYear}
                category={props.category}
                portrait={props.horizontal || props.gridView}
                fullWidth={props.gridView}
                mapView={props.mapView}
                isMobileVersion={props.isMobileVersion}
                mapFocused={props.mapFocused}
                setMapFocused={props.setMapFocused}
                isPlaylistMode={props.isPlaylistMode}
                isChartsMode={props.isChartsMode}
                isGuides={props.isGuides}
                isHeading={props.isHeading}
                withoutBadge={props.withoutBadge}
                chartIndex={parseInt(props.currentPageIndex) + index}
                marginBottom={props.marginBottom}
                firstIndex={index === 0}
                lastIndex={index === props.items.length - 1}
                // onItemAction={onItemAction}
              />
            </a>
          </Link>
          {props.isPlaylistMode && (
            <TouchableOpacity
              style={[
                styles.infoBox,
                props.isMobileVersion
                  ? { width: 50, height: 16, top: 12, right: 16 }
                  : {},
              ]}
              onPress={() => {
                if (item?.id !== showSelectedItem) {
                  setShowSelectedItem(item?.id);
                  setArtistSelectedItem([]);
                } else {
                  setShowSelectedItem(null);
                }
              }}>
              <div
                className="bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
              <Image
                width={props.isMobileVersion ? 8 : 12}
                height={props.isMobileVersion ? 10 : 16}
                src="/icons/ic_search_artist_white_web.png"
                alt="lineup"
                layout="fixed"
              />
              <View
                style={[
                  styles.infoBoxText,
                  props.isMobileVersion
                    ? { fontSize: 10, lineHeight: 10, marginLeft: 4 }
                    : {},
                ]}>
                {item?.matchedLineup}
              </View>
            </TouchableOpacity>
          )}
          <div
            className={`bg-black7 -mt-8 py-4 rounded-2xl mb-4 ${
              showSelectedItem === item?.id ? "block" : "hidden"
            }`}>
            <LineupStatusView
              artistCount={item?.totalLineup}
              headlinerCount={item?.matchedLineup}
              isMatchedLineup={true}
              isMobileVersion={props.isMobileVersion}
              style={{
                height: "auto",
                paddingVertical: props.isMobileVersion ? 15 : 40,
              }}
            />
            <ScrollView style={{ flex: 1 }}>
              {isLoadingLineup && (
                <ActivityIndicator
                  color={Colors.white}
                  size={props.isMobileVersion ? "small" : "large"}
                  style={{ marginBottom: 20 }}
                />
              )}
              <TagList
                items={artistSelectedItem}
                // onPressItem={onPressItem}
                style={{ marginHorizontal: 16, justifyContent: "center" }}
                onRefresh={() => {}}
                onLoadMore={() => {}}
                isMobileVersion={props.isMobileVersion}
              />
            </ScrollView>
          </div>
        </View>
      );
    };
    const onLoadMore = () => {
      if (props.onLoadMore && !props.isLoadingNextPage) {
        props.onLoadMore();
      }
    };
    const onRefresh = () => {
      if (props.onRefresh) {
        props.onRefresh();
      }
    };
    const loadingSkeleton = () => {
      return (
        <div
          className={`animate-pulse ${
            props.isMobileVersion ? "" : "rounded-2xl"
          } ${props.gridView ? "mr-3 mb-6" : "mb-3"} overflow-hidden relative`}>
          {props.gridView && <div className="w-full bg-grayText h-250px"></div>}
          {props.category !== Constants.categoryType.CATEGORY_ARTIST &&
            props.gridView && (
              <div className="absolute top-0 p-4">
                <div className="w-14 h-16 bg-white3 rounded-xl"></div>
              </div>
            )}
          {props.gridView ? (
            <div className="absolute bottom-0 p-4">
              <div className="w-36 bg-white3 h-3 rounded-md mb-3"></div>
              <div className="w-16 bg-white3 h-3 rounded-md mb-3"></div>
              <div className="w-32 bg-white3 h-3 rounded-md"></div>
            </div>
          ) : (
            <div className="flex flex-row items-center bg-grayText">
              {props.category !== Constants.categoryType.CATEGORY_ARTIST && (
                <div
                  className={`flex flex-col items-center justify-center sm:w-16 w-10`}>
                  <div className="sm:w-8 w-6 bg-white3 sm:h-2 h-1 rounded-md sm:mb-3 mb-2"></div>
                  <div className="sm:w-6 w-4 bg-white3 sm:h-2 h-1 rounded-md sm:mb-3 mb-2"></div>
                  <div className="sm:w-8 w-6 bg-white3 sm:h-2 h-1 rounded-md"></div>
                </div>
              )}
              <div className="w-24 h-24 bg-white3"></div>
              <div className="sm:ml-8 ml-4">
                <div className="sm:w-36 w-20 bg-white3 sm:h-3 h-2 rounded-md mb-4"></div>
                <div className="sm:w-64 w-40 bg-white3 sm:h-3 h-2 rounded-md"></div>
              </div>
            </div>
          )}
        </div>
      );
    };
    const renderItemList = (items) => {
      const { forwardedRef } = props;
      if (!items) return null;
      if (props.showInView) {
        return renderItemListInView(items);
      }
      return props.horizontal ? (
        <FlatList
          ref={forwardedRef}
          data={items}
          bounces={!props.horizontal}
          horizontal={props.horizontal}
          renderItem={({ item, index }) => renderItem(item, index)}
          keyExtractor={(item, index) => index.toString()}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          extraData={props}
          ListHeaderComponent={props.ListHeaderComponent}
          numColumns={
            props.gridView
              ? props.category === Constants.categoryType.CATEGORY_ARTIST
                ? 6
                : props.mapView
                ? 3
                : Values.deviceWidth < 1850
                ? 3
                : 4
              : null
          }
          ListEmptyComponent={
            !props.isLoading && (
              <View style={!props.horizontal ? { paddingHorizontal: 20 } : {}}>
                <Text
                  style={{
                    color: Colors.white50,
                    fontFamily: Fonts.primaryRegular,
                    fontSize: 16,
                    lineHeight: 20,
                  }}></Text>
              </View>
            )
          }
          ListFooterComponent={
            props.isLoadingNextPage ? (
              <View style={{ height: props.gapHeight || 0 }}>
                <ActivityIndicator />
              </View>
            ) : (
              <View style={{ height: props.gapHeight || 0 }} />
            )
          }
          refreshControl={
            <RefreshControl
              refreshing={props.isExploreSearch ? false : props.isLoading}
              onRefresh={() => {
                onRefresh();
              }}
              tintColor="#fff"
              titleColor="#fff"
            />
          }
          onEndReachedThreshold={0.01}
          onEndReached={props.disabledInfiniteScroll ? null : onLoadMore()}
          style={[
            !props.horizontal ? { paddingBottom: 30 } : {},
            props.gridView ? { marginRight: "-3%" } : {},
          ]}
        />
      ) : (
        <div>
          {props.isFlatList ? (
            <FlatList
              ref={forwardedRef}
              data={items}
              bounces={!props.horizontal}
              horizontal={props.horizontal}
              renderItem={({ item, index }) => renderItem(item, index)}
              keyExtractor={(item, index) => index.toString()}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              extraData={props}
              ListHeaderComponent={props.ListHeaderComponent}
              numColumns={
                props.gridView
                  ? props.category === Constants.categoryType.CATEGORY_ARTIST
                    ? 6
                    : props.mapView
                    ? 3
                    : Values.deviceWidth < 1850
                    ? 3
                    : 4
                  : null
              }
              ListEmptyComponent={
                !props.isLoading && (
                  <View
                    style={!props.horizontal ? { paddingHorizontal: 20 } : {}}>
                    <Text
                      style={{
                        color: Colors.white50,
                        fontFamily: Fonts.primaryRegular,
                        fontSize: 16,
                        lineHeight: 20,
                      }}></Text>
                  </View>
                )
              }
              ListFooterComponent={
                props.isLoadingNextPage ? (
                  <View style={{ height: props.gapHeight || 0 }}>
                    <ActivityIndicator />
                  </View>
                ) : (
                  <View style={{ height: props.gapHeight || 0 }} />
                )
              }
              refreshControl={
                <RefreshControl
                  refreshing={props.isExploreSearch ? false : props.isLoading}
                  onRefresh={() => {
                    onRefresh();
                  }}
                  tintColor="#fff"
                  titleColor="#fff"
                />
              }
              onEndReachedThreshold={0.01}
              onEndReached={props.disabledInfiniteScroll ? null : onLoadMore()}
              style={[
                !props.horizontal ? { paddingBottom: 30 } : {},
                props.gridView ? { marginRight: "-3%" } : {},
              ]}
            />
          ) : (
            <InfiniteScroll
              dataLength={items.length / 2}
              next={() => (props.disabledInfiniteScroll ? null : onLoadMore())}
              hasMore={true}
              className={` 
                 ${
                   props.gridView
                     ? props.category === Constants.categoryType.CATEGORY_ARTIST
                       ? "grid 2xl:grid-cols-6 grid-cols-4 gap-0"
                       : props.mapView
                       ? "grid 2xl:grid-cols-1 grid-cols-1 gap-0"
                       : "grid 2xl:grid-cols-4 grid-cols-3 gap-0"
                     : null
                 } ${props.gridView ? "-mr-2" : ""}`}
              scrollThreshold={0.2}>
              {items.map((item, index) => renderItem(item, index))}

              {(props.isLoadingNextPage || props.isLoading) &&
                loadingSkeleton()}
              {(props.isLoadingNextPage || props.isLoading) &&
                loadingSkeleton()}
              {(props.isLoadingNextPage || props.isLoading) &&
                loadingSkeleton()}
              {(props.isLoadingNextPage || props.isLoading) &&
                loadingSkeleton()}
              {(props.isLoadingNextPage || props.isLoading) &&
                props.category === Constants.categoryType.CATEGORY_ARTIST &&
                loadingSkeleton()}
              {(props.isLoadingNextPage || props.isLoading) &&
                props.category === Constants.categoryType.CATEGORY_ARTIST &&
                loadingSkeleton()}
            </InfiniteScroll>
          )}
          {props.isLoadingNextPage || props.isLoading ? (
            <View style={{ height: props.gapHeight || 0 }}>
              {/* <ActivityIndicator /> */}
            </View>
          ) : (
            <View style={{ height: props.gapHeight || 0 }} />
          )}
        </div>
      );
    };
    const renderItemListInView = (items) => {
      return items.map((item, index) => {
        return renderItem(item, index);
      });
    };

    return (
      <View
        style={[
          styles.cardListContainer,
          { flexDirection: props.horizontal ? "row" : "column" },
          props.style,
        ]}>
        {renderItemList(props.items)}
      </View>
    );
  };

  return <CardList {...wrapperProps} forwardedRef={ref} />;
});

const styles = StyleSheet.create({
  cardListContainer: {
    flexDirection: "row",
    alignSelf: "stretch",
  },
  infoBox: {
    position: "absolute",
    right: 56,
    top: 34,
    width: 100,
    height: 32,
    borderRadius: 60,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    cursor: "pointer",
  },
  infoBoxText: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    position: "relative",
    zIndex: 10,
    marginLeft: 8,
  },
});

export default WrappedCardList;
