import React, { useState } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Image,
  TouchableOpacity,
  Text,
} from "react-native-web";
import { CImage, DotSplitedText } from "../../../atoms";
import { Fonts, Colors, Constants, Images } from "../../../../config";
import { formatDate } from "../../../../helpers";
import {
  diffFormat,
  formatCurrency,
  formatNumber,
  formatTagRange,
  getClass,
  getSpecialist,
} from "../../../../helpers/formatHelpers";
import DotSplitedViews from "../../../atoms/DotSplitedViews";
import { minBy } from "lodash";

const EventCardInfoView = (props) => {
  const { isMobileVersion, withoutBadge } = props;
  const {
    startDate,
    endDate,
    streetLocation,
    location,
    price,
    city,
    totalConnection,
    totalRelatedConnection,
    totalFollower,
    totalGoing,
    totalSaved,
    totalLineup,
    isOfficial,
    isPro,
  } = props.item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  const festivalStartDate = formatDate(
    startDate,
    Constants.dateFormat.DATE_FORMAT_SIMPLE
  );
  const festivalStartTime = diffFormat(startDate, endDate, "days");
  const dataLocation = streetLocation || city || location;
  const formatedPrice = formatCurrency(price, Constants.currencyType.USD);
  const totalShare = formatNumber(totalConnection || totalRelatedConnection);
  const followerCount =
    formatNumber(totalFollower) === "-"
      ? formatNumber(Number(totalGoing) + Number(totalSaved))
      : formatNumber(totalFollower);

  return (
    <View style={[styles.container, props.style]}>
      <View
        style={[styles.rowContainer]}
        onMouseOver={() => setShowPopupOfficial(true)}
        onMouseLeave={() => setShowPopupOfficial(false)}>
        <Text
          style={[
            styles.title,
            isMobileVersion
              ? {
                  fontSize: 12,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {props.item.title}
        </Text>
        {(isOfficial === 1 || isPro === 1) && !withoutBadge && (
          <View
            style={{
              marginLeft: 6,
              marginTop: 0,
              position: "relative",
            }}>
            <CImage
              source={Images.iconVerifiedBadge}
              alt="badge official"
              width={14}
              height={14}
              layout="fixed"
            />
          </View>
        )}

        {showPopupOfficial && (isOfficial === 1 || isPro === 1) && (
          <View
            style={{
              position: "absolute",
              width: isMobileVersion ? 260 : 336,
              height: isMobileVersion ? 140 + 24 : 182 + 24,
              zIndex: 10,
              left: -10,
              top: 0,
              paddingTop: 24,
            }}>
            <View
              style={{
                backgroundColor: getClass("bg-gray1"),
                width: isMobileVersion ? 260 : 336,
                height: isMobileVersion ? 140 : 182,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 22,
                borderRadius: 18,
                width: "100%",
                height: "100%",
                boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
              }}>
              <CImage
                source={Images.iconVerifiedBadgeMedium}
                alt="verified account"
                width={isMobileVersion ? 24 : 35}
                height={isMobileVersion ? 24 : 35}
                layout="fixed"
              />
              <Text
                style={{
                  fontFamily: Fonts.primaryBold,
                  fontSize: isMobileVersion ? 16 : 20,
                  lineHeight: isMobileVersion ? 20 : 24,
                  color: Colors.white,
                  marginVertical: isMobileVersion ? 9 : 14,
                  textAlign: "center",
                }}>
                Official Account
              </Text>
              <Text
                style={{
                  fontFamily: Fonts.primaryRegular,
                  fontSize: isMobileVersion ? 12 : 16,
                  lineHeight: isMobileVersion ? 16 : 18,
                  color: Colors.white80,
                  textAlign: "center",
                }}>
                This is an official account verified by{" "}
                {process.env.NEXT_PUBLIC_APP_NAME}
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {festivalStartTime}
        </Text>
        <View style={styles.dot} />
        <Text
          style={[
            styles.description,
            { flex: 1 },
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {dataLocation}
        </Text>
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <View style={[styles.rowContainer]}>
          <CImage
            key={1}
            source={Images.iconSearchArtist}
            width={18}
            height={18}
            layout="fixed"
            alt="lineup artists"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>
            {formatNumber(totalLineup)}
          </Text>
        </View>
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconSearchOther}
            width={20}
            height={20}
            layout="fixed"
            alt="followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>
            {followerCount}
          </Text>
        </View> */}
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconShareColored}
            width={16}
            height={16}
            layout="fixed"
            alt="shared followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>{"-"}</Text>
        </View> */}
      </View>
    </View>
  );
};

const FestivalCardInfoView = (props) => {
  const { isMobileVersion, withoutBadge } = props;
  const {
    startDate,
    endDate,
    streetLocation,
    location,
    price,
    city,
    totalConnection,
    totalRelatedConnection,
    totalFollower,
    totalGoing,
    totalSaved,
    totalLineup,
    isOfficial,
    isPro,
  } = props.item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  const festivalStartDate = formatDate(
    startDate,
    Constants.dateFormat.DATE_FORMAT_SIMPLE
  );
  const festivalStartTime = diffFormat(startDate, endDate, "days");
  const dataLocation = streetLocation || city || location;
  const formatedPrice = formatCurrency(price, Constants.currencyType.USD);
  const totalShare = formatNumber(totalConnection || totalRelatedConnection);
  const followerCount =
    formatNumber(totalFollower) === "-"
      ? formatNumber(Number(totalGoing) + Number(totalSaved))
      : formatNumber(totalFollower);

  return (
    <View style={[styles.container, props.style]}>
      <View
        style={[styles.rowContainer]}
        onMouseOver={() => setShowPopupOfficial(true)}
        onMouseLeave={() => setShowPopupOfficial(false)}>
        <Text
          style={[
            styles.title,
            isMobileVersion
              ? {
                  fontSize: 12,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {props.item.title}
        </Text>
        {(isOfficial === 1 || isPro === 1) && !withoutBadge && (
          <View
            style={{
              marginLeft: 6,
              marginTop: 0,
              position: "relative",
            }}>
            <CImage
              source={Images.iconVerifiedBadge}
              alt="badge official"
              width={14}
              height={14}
              layout="fixed"
            />
          </View>
        )}

        {showPopupOfficial && (isOfficial === 1 || isPro === 1) && (
          <View
            style={{
              position: "absolute",
              width: isMobileVersion ? 260 : 336,
              height: isMobileVersion ? 140 + 24 : 182 + 24,
              zIndex: 10,
              left: -10,
              top: 0,
              paddingTop: 24,
            }}>
            <View
              style={{
                backgroundColor: getClass("bg-gray1"),
                width: isMobileVersion ? 260 : 336,
                height: isMobileVersion ? 140 : 182,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 22,
                borderRadius: 18,
                width: "100%",
                height: "100%",
                boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
              }}>
              <CImage
                source={Images.iconVerifiedBadgeMedium}
                alt="verified account"
                width={isMobileVersion ? 24 : 35}
                height={isMobileVersion ? 24 : 35}
                layout="fixed"
              />
              <Text
                style={{
                  fontFamily: Fonts.primaryBold,
                  fontSize: isMobileVersion ? 16 : 20,
                  lineHeight: isMobileVersion ? 20 : 24,
                  color: Colors.white,
                  marginVertical: isMobileVersion ? 9 : 14,
                  textAlign: "center",
                }}>
                Official Account
              </Text>
              <Text
                style={{
                  fontFamily: Fonts.primaryRegular,
                  fontSize: isMobileVersion ? 12 : 16,
                  lineHeight: isMobileVersion ? 16 : 18,
                  color: Colors.white80,
                  textAlign: "center",
                }}>
                This is an official account verified by{" "}
                {process.env.NEXT_PUBLIC_APP_NAME}
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {festivalStartTime}
        </Text>
        <View style={styles.dot} />
        <Text
          style={[
            styles.description,
            { flex: 1 },
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {dataLocation}
        </Text>
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <View style={[styles.rowContainer]}>
          <CImage
            key={1}
            source={Images.iconSearchArtist}
            width={18}
            height={18}
            layout="fixed"
            alt="lineup artists"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>
            {formatNumber(totalLineup)}
          </Text>
        </View>
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconSearchOther}
            width={20}
            height={20}
            layout="fixed"
            alt="followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>
            {followerCount}
          </Text>
        </View> */}
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconShareColored}
            width={16}
            height={16}
            layout="fixed"
            alt="shared followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>{"-"}</Text>
        </View> */}
      </View>
    </View>
  );
};

const ArtistCardInfoView = (props) => {
  const { isMobileVersion } = props;
  const totalEventString = formatNumber(props.item.totalEvent);
  const totalFollowerString = props.item.totalFollower || "-";
  const totalShareString = formatNumber(props.item.totalConnection) || "-";
  const totalLineup = props.item.totalLineup || "-";
  const renderViews = () => {
    return [
      <View key={1} style={styles.rowContainer}>
        <CImage
          key={1}
          source={Images.iconTicket}
          width={16}
          height={16}
          layout="fixed"
          alt="ticket entry"
        />
        <Text
          key={2}
          style={[styles.infoText, { marginLeft: 4, marginRight: 10 }]}>
          {totalEventString}
        </Text>
        {/* <CImage
          source={Images.iconSearchOther}
          width={20}
          height={20}
          layout="fixed"
          alt="followers"
        />
        <Text key={4} style={[styles.infoText, { marginLeft: 4 }]}>
          {totalFollowerString}
        </Text> */}
      </View>,
    ];
  };
  return (
    <View style={[styles.container, props.style]}>
      <Text
        style={[
          styles.title,
          isMobileVersion
            ? {
                fontSize: 12,
                lineHeight: 18,
              }
            : {},
        ]}
        numberOfLines={1}>
        {props.item.displayName}
      </Text>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {getSpecialist(props.item.specialist?.split(","))}
        </Text>
        <View style={styles.dot} />
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {props.item.country || props.item.location}
        </Text>
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <DotSplitedViews views={renderViews()} />
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconShareColored}
            width={16}
            height={16}
            layout="fixed"
            alt="shared followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>{"-"}</Text>
        </View> */}
      </View>
    </View>
  );
};

const VenueCardInfoView = (props) => {
  const { isMobileVersion, category, withoutBadge } = props;
  const { isOfficial, isPro } = props.item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  const totalEventString = formatNumber(props.item.totalEvent);
  const totalFollowerString = props.item.totalFollower || "-";
  const totalShareString = formatNumber(props.item.totalConnection) || "-";
  const totalLineup = props.item.totalLineup || "-";
  const renderViews = () => {
    return [
      <View key={1} style={styles.rowContainer}>
        {/* <CImage
          key={1}
          source={Images.iconTicket}
          width={16}
          height={16}
          layout="fixed"
          alt="ticket entry"
        />
        <Text
          key={2}
          style={[styles.infoText, { marginLeft: 4, marginRight: 10 }]}>
          {totalEventString}
        </Text> */}
        <CImage
          source={Images.iconSearchOther}
          width={20}
          height={20}
          layout="fixed"
          alt="followers"
        />
        <Text key={4} style={[styles.infoText, { marginLeft: 4 }]}>
          {totalFollowerString}
        </Text>
      </View>,
    ];
  };
  return (
    <View style={[styles.container, props.style]}>
      <View
        style={[styles.rowContainer]}
        onMouseOver={() => setShowPopupOfficial(true)}
        onMouseLeave={() => setShowPopupOfficial(false)}>
        <Text
          style={[
            styles.title,
            isMobileVersion
              ? {
                  fontSize: 12,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {props.item.displayName}
        </Text>
        {(isOfficial === 1 || isPro === 1) && !withoutBadge && (
          <View
            style={{
              marginLeft: 6,
              marginTop: 0,
              position: "relative",
            }}>
            <CImage
              source={Images.iconVerifiedBadge}
              alt="badge official"
              width={14}
              height={14}
              layout="fixed"
            />
          </View>
        )}

        {showPopupOfficial && (isOfficial === 1 || isPro === 1) && (
          <View
            style={{
              position: "absolute",
              width: isMobileVersion ? 260 : 336,
              height: isMobileVersion ? 140 + 24 : 182 + 24,
              zIndex: 10,
              left: -10,
              top: 0,
              paddingTop: 24,
            }}>
            <View
              style={{
                backgroundColor: getClass("bg-gray1"),
                width: isMobileVersion ? 260 : 336,
                height: isMobileVersion ? 140 : 182,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 22,
                borderRadius: 18,
                width: "100%",
                height: "100%",
                boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
              }}>
              <CImage
                source={Images.iconVerifiedBadgeMedium}
                alt="verified account"
                width={isMobileVersion ? 24 : 35}
                height={isMobileVersion ? 24 : 35}
                layout="fixed"
              />
              <Text
                style={{
                  fontFamily: Fonts.primaryBold,
                  fontSize: isMobileVersion ? 16 : 20,
                  lineHeight: isMobileVersion ? 20 : 24,
                  color: Colors.white,
                  marginVertical: isMobileVersion ? 9 : 14,
                  textAlign: "center",
                }}>
                Official Account
              </Text>
              <Text
                style={{
                  fontFamily: Fonts.primaryRegular,
                  fontSize: isMobileVersion ? 12 : 16,
                  lineHeight: isMobileVersion ? 16 : 18,
                  color: Colors.white80,
                  textAlign: "center",
                }}>
                This is an official account verified by{" "}
                {process.env.NEXT_PUBLIC_APP_NAME}
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        {category === Constants.categoryType.CATEGORY_PREFAB_HOMES ? (
          <Text
            style={[
              styles.description,
              isMobileVersion
                ? {
                    fontSize: 10,
                    lineHeight: 18,
                  }
                : {},
            ]}>
            {props.item.company_name || "-"}
          </Text>
        ) : (
          <>
            {category === Constants.categoryType.CATEGORY_ANIMAL ? null : (
              <Text
                style={[
                  styles.description,
                  isMobileVersion
                    ? {
                        fontSize: 10,
                        lineHeight: 18,
                      }
                    : {},
                ]}>
                {props.item.city || "-"}
              </Text>
            )}
          </>
        )}
      </View>
      {category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ? (
        <View style={[styles.rowContainer, { marginTop: 10 }]}>
          <Text
            style={[
              styles.description,
              isMobileVersion
                ? {
                    fontSize: 10,
                    lineHeight: 18,
                  }
                : {},
            ]}>
            {`${props.item.company_homes?.length} Homes`}
          </Text>
          {minBy(props.item.company_homes, "minPrice")?.minPrice && (
            <View style={styles.dot} />
          )}
          {minBy(props.item.company_homes, "minPrice")?.minPrice && (
            <Text
              style={[
                styles.description,
                isMobileVersion
                  ? {
                      fontSize: 10,
                      lineHeight: 18,
                    }
                  : {},
              ]}>
              {`From ${formatCurrency(
                formatNumber(
                  minBy(props.item.company_homes, "minPrice")?.minPrice
                )
              )}`}
            </Text>
          )}
        </View>
      ) : (
        <>
          {category === Constants.categoryType.CATEGORY_ZOO ? (
            <View style={[styles.rowContainer, { marginTop: 10 }]}>
              {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                (i) => i === "Animals"
              ) && (
                <Text
                  style={[
                    styles.description,
                    isMobileVersion
                      ? {
                          fontSize: 10,
                          lineHeight: 18,
                        }
                      : {},
                  ]}>
                  {`${props.item.company_homes?.length} Animals`}
                </Text>
              )}
              {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                (i) => i === "Animals"
              ) && <View style={styles.dot} />}
              {/* {props.item.tag_price && (
                <Text
                  style={[
                    styles.description,
                    isMobileVersion
                      ? {
                          fontSize: 10,
                          lineHeight: 18,
                        }
                      : {},
                  ]}>
                  {`From ${formatCurrency(formatNumber(props.item.tag_price))}`}
                </Text>
              )} */}
              {/* {props.item.tag_price && props.item.tag_species_animal && (
                <View style={styles.dot} />
              )} */}
              {props.item.tag_species_animal && (
                <Text
                  style={[
                    styles.description,
                    isMobileVersion
                      ? {
                          fontSize: 10,
                          lineHeight: 18,
                        }
                      : {},
                  ]}>
                  {`Animals ${formatTagRange(
                    Constants.tagCategory.TAG_CATEGORY_ZOO_SPECIES,
                    props.item.tag_species_animal
                  )}`}
                </Text>
              )}
            </View>
          ) : (
            <View style={[styles.rowContainer, { marginTop: 10 }]}>
              <Text
                style={[
                  styles.description,
                  isMobileVersion
                    ? {
                        fontSize: 10,
                        lineHeight: 18,
                      }
                    : {},
                ]}>
                {category === Constants.categoryType.CATEGORY_VENUE ? (
                  props.item.tag_venue_1 || "-"
                ) : category ===
                  Constants.categoryType.CATEGORY_PREFAB_HOMES ? (
                  props.item?.tag_category?.filter((i, index) => index <= 0)
                    ?.length > 0 ? (
                    props.item?.tag_category
                      ?.filter((i, index) => index <= 0)
                      ?.join(", ") +
                      (props.item?.tag_category?.length > 1
                        ? " +" + (props.item?.tag_category?.length - 1)
                        : "") || "-"
                  ) : (
                    "-"
                  )
                ) : category === Constants.categoryType.CATEGORY_PARKFUL ? (
                  props.item?.tag_category?.filter((i, index) => index <= 1)
                    ?.length > 0 ? (
                    props.item?.tag_category
                      ?.filter((i, index) => index <= 0)
                      ?.join(", ") +
                      (props.item?.tag_category?.length > 1
                        ? " +" + (props.item?.tag_category?.length - 1)
                        : "") || "-"
                  ) : (
                    "-"
                  )
                ) : category === Constants.categoryType.CATEGORY_CASINO ? (
                  props.item?.tag_category || "-"
                ) : category ===
                  Constants.categoryType.CATEGORY_CASTLE ? null : category === // ? props.item?.tag_category || "-"
                  Constants.categoryType.CATEGORY_RESORT ? (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 2,
                    }}>
                    <CImage
                      key={1}
                      width={16}
                      height={16}
                      layout="fixed"
                      source={Images.iconTagStar}
                      style={styles.icon}
                      resizeMode="contain"
                      alt="star"
                    />
                    <Text style={{ marginLeft: 4 }}>
                      {props.item?.tag_star || "-"}
                    </Text>
                  </View>
                ) : category === Constants.categoryType.CATEGORY_APARTHOTEL ? (
                  props.item?.tag_category || "-"
                ) : category === Constants.categoryType.CATEGORY_ANIMAL ? (
                  props.item?.tag_habitat?.filter((i, index) => index <= 1)
                    ?.length > 0 ? (
                    props.item?.tag_habitat
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ") +
                      (props.item?.tag_habitat?.length > 2
                        ? " +" + (props.item?.tag_habitat?.length - 1)
                        : "") || "-"
                  ) : (
                    "-"
                  )
                ) : (
                  props.item.tag_venue_2 || "-"
                )}
              </Text>
              {category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
              category === Constants.categoryType.CATEGORY_PARKFUL ||
              category === Constants.categoryType.CATEGORY_CASTLE ? null : (
                // <>{props.item?.tag_price && <View style={styles.dot} />}</>
                <View style={styles.dot} />
              )}
              <Text
                style={[
                  styles.description,
                  isMobileVersion
                    ? {
                        fontSize: 10,
                        lineHeight: 18,
                      }
                    : {},
                ]}>
                {category === Constants.categoryType.CATEGORY_VENUE ||
                category === Constants.categoryType.CATEGORY_SPA
                  ? props.item?.tag_feature?.filter((i, index) => index <= 1)
                      ?.length > 0
                    ? props.item?.tag_feature
                        ?.filter((i, index) => index <= 1)
                        ?.join(", ") +
                        (props.item?.tag_feature?.length > 2
                          ? " +" + (props.item?.tag_feature?.length - 2)
                          : "") || "-"
                    : "-"
                  : category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
                    category === Constants.categoryType.CATEGORY_PARKFUL
                  ? props.item?.tag_price
                    ? null
                    : // `From ${formatCurrency(
                      //     formatNumber(props.item?.tag_price)
                      //   )}`
                      null
                  : category === Constants.categoryType.CATEGORY_ANIMAL
                  ? props.item?.tag_category?.filter((i, index) => index <= 1)
                      ?.length > 0
                    ? props.item?.tag_category
                        ?.filter((i, index) => index <= 1)
                        ?.join(", ") +
                        (props.item?.tag_category?.length > 2
                          ? " +" + (props.item?.tag_category?.length - 2)
                          : "") || "-"
                    : "-"
                  : category === Constants.categoryType.CATEGORY_CASINO
                  ? props.item?.tag_feature?.filter((i, index) => index <= 1)
                      ?.length > 0
                    ? props.item?.tag_feature
                        ?.filter((i, index) => index <= 1)
                        ?.join(", ") +
                        (props.item?.tag_feature?.length > 2
                          ? " +" + (props.item?.tag_feature?.length - 2)
                          : "") || "-"
                    : "-"
                  : category === Constants.categoryType.CATEGORY_CASTLE
                  ? null
                  : // ? props.item?.tag_area?.join(", ")
                  category === Constants.categoryType.CATEGORY_RESORT
                  ? props.item?.tag_feature?.filter((i, index) => index <= 1)
                      ?.length > 0
                    ? props.item?.tag_feature
                        ?.filter((i, index) => index <= 1)
                        ?.join(", ") +
                        (props.item?.tag_feature?.length > 2
                          ? " +" + (props.item?.tag_feature?.length - 2)
                          : "") || "-"
                    : "-"
                  : category === Constants.categoryType.CATEGORY_APARTHOTEL
                  ? props.item?.tag_feature?.filter((i, index) => index <= 1)
                      ?.length > 0
                    ? props.item?.tag_feature
                        ?.filter((i, index) => index <= 1)
                        ?.join(", ") +
                        (props.item?.tag_feature?.length > 2
                          ? " +" + (props.item?.tag_feature?.length - 2)
                          : "") || "-"
                    : "-"
                  : props.item?.tag_service?.filter((i, index) => index <= 1)
                      ?.length > 0
                  ? props.item?.tag_service
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ") +
                      (props.item?.tag_service?.length > 2
                        ? " +" + (props.item?.tag_service?.length - 2)
                        : "") || "-"
                  : "-"}
              </Text>
              {category === Constants.categoryType.CATEGORY_PREFAB_HOMES && (
                <View style={styles.dot} />
              )}
              {category === Constants.categoryType.CATEGORY_PREFAB_HOMES && (
                <Text
                  style={[
                    styles.description,
                    isMobileVersion
                      ? {
                          fontSize: 10,
                          lineHeight: 18,
                        }
                      : {},
                  ]}>
                  {formatNumber(props.item?.tag_size)} sqft
                </Text>
              )}
            </View>
          )}
        </>
      )}
      {/* <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <DotSplitedViews views={renderViews()} />
      </View> */}
    </View>
  );
};

const CardInfoView = (props) => {
  const { isMobileVersion } = props;
  if (!props.item) return null;
  if (props.category == Constants.categoryType.CATEGORY_EVENT) {
    return <EventCardInfoView {...props} />;
  } else if (props.category == Constants.categoryType.CATEGORY_FESTIVAL) {
    return <FestivalCardInfoView {...props} />;
  } else if (props.category == Constants.categoryType.CATEGORY_ARTIST) {
    return <ArtistCardInfoView {...props} />;
  } else if (
    props.category == Constants.categoryType.CATEGORY_VENUE ||
    props.category == Constants.categoryType.CATEGORY_ROOFTOP ||
    props.category == Constants.categoryType.CATEGORY_SPA ||
    props.category == Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
    props.category == Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
    props.category == Constants.categoryType.CATEGORY_PREFAB_HOMES ||
    props.category == Constants.categoryType.CATEGORY_ZOO ||
    props.category == Constants.categoryType.CATEGORY_ANIMAL ||
    props.category == Constants.categoryType.CATEGORY_PARKFUL ||
    props.category == Constants.categoryType.CATEGORY_CASINO ||
    props.category == Constants.categoryType.CATEGORY_CASTLE ||
    props.category == Constants.categoryType.CATEGORY_RESORT ||
    props.category == Constants.categoryType.CATEGORY_APARTHOTEL ||
    props.category == Constants.categoryType.CATEGORY_VENUE_COCKTAIL
  ) {
    return <VenueCardInfoView {...props} />;
  }

  return (
    <View style={[styles.container, props.style]}>
      <Text
        style={[
          styles.title,
          isMobileVersion
            ? {
                fontSize: 12,
                lineHeight: 18,
              }
            : {},
        ]}>
        {props.item.title}
      </Text>
      <Text
        style={[
          styles.description,
          isMobileVersion
            ? {
                fontSize: 10,
                lineHeight: 18,
              }
            : {},
        ]}>
        {props.item.shortDescription}
      </Text>
      <View style={styles.rowContainer}>
        <Text style={styles.location}>{props.item.city}</Text>
        {props.item.gemCount && <View style={styles.dot} />}
        {props.item.gemCount && (
          <View style={styles.row}>
            <CImage
              key={1}
              width={16}
              height={16}
              layout="fixed"
              source={Images.iconTicket}
              style={styles.icon}
              resizeMode="contain"
              alt="ticket entry"
            />
            <Text style={styles.location}>{props.item.gemCount}</Text>
          </View>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    textAlign: "left",
  },
  description: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: Fonts.primaryRegular,
    color: Colors.darkGray,
  },
  dot: {
    height: 2,
    width: 2,
    borderRadius: 1,
    marginHorizontal: 6,
    backgroundColor: Colors.lightGray,
  },
  location: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryMedium,
    color: Colors.lightGray,
  },
  infoText: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: Fonts.primaryRegular,
    color: Colors.lightGray,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
});
export default CardInfoView;
