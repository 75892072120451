import { StyleSheet, Text, View } from "react-native-web";
import React, { useState } from "react";
import { Colors, Fonts } from "../../../config";
// import { Switch } from "react-native-switch";

const NotificationSwitch = ({
  title,
  defaultValue1,
  defaultValue2,
  setDefaultValue1,
  setDefaultValue2,
  hideSwitch2,
}) => {
  return (
    <View style={{ marginBottom: 20 }}>
      <Text style={styles.itemTitle}>{title}</Text>
      <View
        style={{
          backgroundColor: Colors.grayBackground,
          paddingHorizontal: 25,
          paddingVertical: 20,
          borderRadius: 16,
          marginTop: 10,
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text
            style={{
              fontSize: 16,
              color: Colors.white,
              fontFamily: Fonts.primaryRegular,
              textAlignVertical: "center",
            }}
          >
            Push notifications
          </Text>
          {/* <Switch
            value={defaultValue1}
            onValueChange={() => setDefaultValue1(!defaultValue1)}
            disabled={false}
            circleSize={20}
            barHeight={14}
            backgroundActive={"rgba(255, 255, 255, 0.38)"}
            backgroundInactive={"gray"}
            circleActiveColor={Colors.white}
            circleInActiveColor={"rgba(255, 255, 255, 0.6)"}
            changeValueImmediately={true} // if rendering inside circle, change state immediately or wait for animation to complete
            innerCircleStyle={{
              alignItems: "center",
              justifyContent: "center",
            }} // style for inner animated circle for what you (may) be rendering inside the circle
            outerCircleStyle={{}} // style for outer animated circle
            renderActiveText={false}
            renderInActiveText={false}
            switchLeftPx={2} // denominator for logic when sliding to TRUE position. Higher number = more space from RIGHT of the circle to END of the slider
            switchRightPx={2} // denominator for logic when sliding to FALSE position. Higher number = more space from LEFT of the circle to BEGINNING of the slider
            switchWidthMultiplier={2} // multiplied by the `circleSize` prop to calculate total width of the Switch
            switchBorderRadius={30} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
          /> */}
        </View>
        {!hideSwitch2 && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 25,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: Colors.white,
                fontFamily: Fonts.primaryRegular,
                textAlignVertical: "center",
              }}
            >
              E-mail
            </Text>
            {/* <Switch
              value={defaultValue2}
              onValueChange={() => setDefaultValue2(!defaultValue2)}
              disabled={false}
              circleSize={20}
              barHeight={14}
              backgroundActive={"rgba(255, 255, 255, 0.38)"}
              backgroundInactive={"gray"}
              circleActiveColor={Colors.white}
              circleInActiveColor={"rgba(255, 255, 255, 0.6)"}
              changeValueImmediately={true} // if rendering inside circle, change state immediately or wait for animation to complete
              innerCircleStyle={{
                alignItems: "center",
                justifyContent: "center",
              }} // style for inner animated circle for what you (may) be rendering inside the circle
              outerCircleStyle={{}} // style for outer animated circle
              renderActiveText={false}
              renderInActiveText={false}
              switchLeftPx={2} // denominator for logic when sliding to TRUE position. Higher number = more space from RIGHT of the circle to END of the slider
              switchRightPx={2} // denominator for logic when sliding to FALSE position. Higher number = more space from LEFT of the circle to BEGINNING of the slider
              switchWidthMultiplier={2} // multiplied by the `circleSize` prop to calculate total width of the Switch
              switchBorderRadius={30} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
            /> */}
          </View>
        )}
      </View>
    </View>
  );
};

export default NotificationSwitch;

const styles = StyleSheet.create({
  itemTitle: {
    fontSize: 14,
    lineHeight: 15,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
});
