import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../../../config";

const SectionView = (props) => {
  const { isMobileVersion } = props;
  const { title, suffix } = props.section;
  return (
    <View
      style={{
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        height: isMobileVersion ? 24 : 40,
        paddingLeft: 1,
        paddingRight: 1,
        borderRadius: 20,
        overflow: "hidden",
        marginBottom: isMobileVersion ? 16 : 30,
        marginTop: isMobileVersion ? 16 : 16,
      }}>
      <div
        className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          justifyContent: "center",
          alignItems: "center",
        }}></div>
      <View
        style={{
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          height: isMobileVersion ? 22 : 38,
          paddingLeft: 29,
          paddingRight: 29,
          borderRadius: 20,
          backgroundColor: Colors.black,
          overflow: "hidden",
        }}>
        <Text
          style={{
            fontFamily: Fonts.primaryBold,
            fontSize: isMobileVersion ? 12 : 18,
            lineHeight: isMobileVersion ? 12 : 32,
            color: "white",
            marginBottom: 32,
            marginTop: 32,
          }}>
          {title}
        </Text>
        {suffix != undefined && suffix != null && (
          <Text
            style={{
              fontFamily: Fonts.primaryBold,
              fontSize: isMobileVersion ? 12 : 18,
              lineHeight: isMobileVersion ? 12 : 32,
              color: Colors.grayText2,
              marginBottom: 32,
              marginTop: 32,
              marginLeft: 10,
            }}>
            {suffix}
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: Colors.black,
  },
  text: {
    fontSize: 18,
    color: Colors.white,
    fontFamily: Fonts.primaryBold,
    lineHeight: 32,
    marginVertical: 32,
  },
});

export default SectionView;
