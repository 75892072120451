import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from "react-native-web";
import Image from "next/image";
import { IconizedButton } from "../../atoms";
import { useTranslation } from "react-i18next";
import { Colors, Fonts, Images } from "../../../config";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";

const PlaylistContainer = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion, items } = props;
  const [value, setValue] = useState("followed");
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(1);

  const onPressConfirm = () => {
    if (props.onPressConfirm) {
      props.onPressConfirm(value);
    }
  };
  return (
    <View style={[styles.container, props.style]}>
      <View
        style={[
          styles.wrapper,
          isMobileVersion ? { paddingVertical: 0, paddingHorizontal: 0 } : {},
        ]}>
        <div className="flex relative">
          <div
            {...buttonProps}
            className="bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1 p-1px rounded-full w-full cursor-pointer">
            <div
              className="flex flex-row items-center justify-center bg-black text-white font-semibold text-center capitalize rounded-full cursor-pointer"
              style={{
                height: isMobileVersion ? 32 : 50,
                fontSize: isMobileVersion ? 12 : 16,
              }}>
              <div className="mr-6">
                {items.find((i) => i.tagId === value)?.label ||
                  "Followed Artists"}
              </div>
              <Image
                src={Images.iconArrowExpandedGray}
                resizeMode="contain"
                width={18}
                height={10}
                alt="Discover festivals with"
              />
            </div>
          </div>
          <div
            className={`${
              isOpen ? "visible" : ""
            } flex flex-col bg-black absolute top-[55px] left-0 rounded-2xl z-[999] max-h-250px overflow-y-auto w-full border border-spliterLine py-4`}
            role="menu">
            {items.map((item, index) => {
              if (item.isTextOnly) {
                return (
                  <Text
                    style={{
                      color: Colors.grayText2,
                      fontFamily: Fonts.primaryRegular,
                      fontSize: isMobileVersion ? 12 : 14,
                      lineHeight: isMobileVersion ? 16 : 18,
                      textAlign: "center",
                      marginVertical: 10,
                    }}>
                    {item.label}
                  </Text>
                );
              } else {
                return (
                  <div
                    {...itemProps[index]}
                    key={index}
                    className={`${
                      isMobileVersion ? "text-xs" : "text-base"
                    } font-regular text-center text-white py-2 cursor-pointer transition-all hover:bg-grayBackground ${
                      value === item.tagId ? "bg-grayBackground" : ""
                    }`}
                    onClick={() => {
                      setValue(item.tagId);
                      setIsOpen(false);
                    }}>
                    {item.label}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </View>
      <View style={styles.footerButton}>
        <View style={styles.footerItem}>
          <IconizedButton
            buttonTitle="CONFIRM"
            colored
            borderRadius={60}
            onPress={() => {
              onPressConfirm();
            }}
            isMobileVersion={isMobileVersion}
            style={isMobileVersion ? { height: 33 } : {}}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  wrapper: {
    maxHeight: 350,
    paddingHorizontal: 32,
    paddingVertical: 20,
  },
  itemStyle: {
    marginBottom: 20,
  },
  separatorLine: {
    backgroundColor: Colors.grayBackground,
    height: 1,
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: 14,
    marginTop: 14,
  },
  row2: {
    borderTopColor: Colors.grayBackground,
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingVertical: 14,
  },
  sliderContainer: {
    paddingHorizontal: 10,
  },
  sliderTitle: {
    fontFamily: Fonts.primaryRegular,
    color: Colors.white,
    fontSize: 14,
    textAlign: "center",
  },
  sliderMarker: {
    backgroundColor: Colors.white,
    height: 24,
    width: 24,
    borderRadius: 100,
    top: 5,
  },
  ageContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingVertical: 16,
    position: "relative",
  },
  ageLabel: {
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    fontSize: 16,
  },
  ageList: {
    position: "relative",
  },
  pickerItemLabel: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: Fonts.primaryBold,
    color: Colors.grey,
  },
  ageItem: {
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    fontSize: 14,
  },
  ageItemPrevNext: {
    fontFamily: Fonts.primaryRegular,
    color: Colors.grey2,
    fontSize: 14,
    position: "absolute",
    width: 50,
  },
  footerButton: {
    borderTopWidth: 1,
    borderTopColor: Colors.grayBackground,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingTop: 12,
    marginTop: 16,
    marginBottom: -6,
    marginHorizontal: -16,
    position: "relative",
    zIndex: -1,
  },
  footerItem: {
    flex: 1,
    paddingHorizontal: 8,
  },
  arrowContainer: {
    width: 40,
    height: 40,
    borderWidth: 1,
    borderRadius: 60,
    borderColor: Colors.grayBorder,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
});

export default PlaylistContainer;
