import React from "react";
import { StyleSheet, View } from "react-native-web";
import { useTranslation } from "react-i18next";
import { IconizedButton } from "../../atoms";

const FollowContainer = (props) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.container, props.style]}>
      <IconizedButton
        buttonTitle={t("key_cancel")}
        style={[styles.buttonStyle, { marginBottom: 16 }]}
        filled
        onPress={props.onPressCancel}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
    paddingTop: 24,
  },
  plusIcon: {
    width: 14,
    height: 14,
    resizeMode: "contain",
    marginRight: 4,
  },
});

export default FollowContainer;
