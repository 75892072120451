import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native-web";
import { useTranslation } from "react-i18next";
import { CImage, IconizedButton, IconizedTextInput } from "../../atoms";
import { Colors, Constants, Fonts, Images, Values } from "../../../config";
import { LocationDao } from "../../../DAO";
import MultiSlider from "../../../../components_web/MultiSlider";
import { useRouter } from "next/router";
import { getClass } from "../../../helpers/formatHelpers";

const LocationContainer = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isMobileVersion, category } = props;
  const scrollRef = useRef(null);
  const [locationSelected, setLocationSelected] = useState(
    props.locationSelected
  );
  const [search, setSearch] = useState("");
  const [tempLocationSelected, setTempLocationSelected] = useState(null);
  const [distance, setDistance] = useState(
    props.distanceValue ||
      (router?.query?.category === "venues"
        ? 16
        : router?.query?.category === "festivals"
        ? 800
        : 160)
  );
  const [items, setItems] = useState([]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onLoad(search === "");
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const onLoad = (isDefault) => {
    const params = {
      keyword: search,
      withoutWorldwideVirtual: props.defaultIsEmpty,
      pageSize: 100,
    };
    if (props.isCityOnly) {
      params.isCityOnly = 1;
    }
    if (props.isCountryOnly) {
      params.isCountryOnly = 1;
    }
    if (props.isShowState) {
      params.isShowState = 1;
    }
    LocationDao.find(params).then((locations) => {
      scrollRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
      if (isDefault && !props.defaultIsEmpty) {
        setItems(
          locations
            .filter((item) => item.name === "World")
            .concat(
              props.isCountryOnly
                ? [
                    {
                      country: "United States",
                      country_id: 233,
                      country_name: "United States",
                      id: undefined,
                      name: undefined,
                      state: undefined,
                    },
                  ]
                : props.isCityOnly
                ? [
                    {
                      city_id: 122795,
                      city_name: "New York City",
                      country: "United States",
                      country_id: 233,
                      country_name: "United States",
                      id: 122795,
                      name: "New York City",
                      state: "New York",
                      state_name: "New York",
                    },
                  ]
                : [
                    {
                      country: "United States",
                      country_id: 233,
                      country_name: "United States",
                      id: undefined,
                      name: undefined,
                      state: undefined,
                    },
                    {
                      state_id: 1452,
                      state_name: "New York",
                    },
                    {
                      city_id: 122795,
                      city_name: "New York City",
                      country: "United States",
                      country_id: 233,
                      country_name: "United States",
                      id: 122795,
                      name: "New York City",
                      state: "New York",
                      state_name: "New York",
                    },
                  ]
            )
            .concat(locations.filter((item) => item.name === "Virtual"))
        );
      } else {
        setItems(locations);
      }
    });
  };
  return (
    <View style={[styles.container, props.style]}>
      {tempLocationSelected ? (
        <View
          style={[
            styles.locationContainer,
            isMobileVersion ? { height: 40 } : {},
          ]}>
          <Text style={styles.locationContent}>
            <Text
              style={[
                styles.listItemName,
                isMobileVersion ? { fontSize: 14 } : {},
              ]}>
              {tempLocationSelected?.name}
            </Text>
            {tempLocationSelected?.state && (
              <Text
                style={[
                  styles.listItemCountry,
                  isMobileVersion ? { fontSize: 14 } : {},
                  { paddingRight: 8 },
                ]}>
                {tempLocationSelected?.state},
              </Text>
            )}
            {tempLocationSelected?.country && (
              <Text
                style={[
                  styles.listItemCountry,
                  isMobileVersion ? { fontSize: 14 } : {},
                ]}>
                {tempLocationSelected?.country}
              </Text>
            )}
          </Text>
          <TouchableOpacity
            onPress={() => setTempLocationSelected(null)}
            style={{ marginRight: 4 }}>
            <CImage
              source={Images.iconClose}
              width={16}
              height={16}
              layout="fixed"
            />
          </TouchableOpacity>
        </View>
      ) : (
        <IconizedTextInput
          placeholder={t("key_search")}
          bordered
          onChangeText={(text) => setSearch(text)}
          value={search}
          maxLength={64}
          autoCapitalize="none"
          autoCorrect={false}
          containerStyle={{
            marginBottom: isMobileVersion ? 16 : 20,
            marginTop: isMobileVersion ? 16 : 24,
            marginHorizontal: isMobileVersion ? 7 : 6,
            height: isMobileVersion ? 38 : 50,
          }}
          returnKeyType="search"
          showClose={search !== ""}
          onPressClose={() => setSearch("")}
          isMobileVersion={isMobileVersion}
        />
      )}
      {props.isDistance &&
      tempLocationSelected &&
      tempLocationSelected?.name &&
      tempLocationSelected?.name !== "World" &&
      tempLocationSelected?.name !== "Virtual" ? (
        <View
          style={[
            styles.distanceContainer,
            isMobileVersion
              ? { paddingHorizontal: 40 }
              : { paddingHorizontal: 40 },
          ]}>
          <View style={styles.distanceTitleContainer}>
            <Text
              style={[
                styles.distanceTitle,
                isMobileVersion ? { fontSize: 12 } : {},
              ]}>
              Distance
            </Text>
            <Text
              style={[
                styles.distanceInfo,
                isMobileVersion ? { fontSize: 12 } : {},
              ]}>
              {distance} km
            </Text>
          </View>
          <View>
            <MultiSlider
              trackGradientColors={["#F526D6", "#A408FE", "#44B3F8"]}
              markerStyle={{
                backgroundColor: getClass("text-white"),
                height: isMobileVersion ? 16 : 24,
                width: isMobileVersion ? 16 : 24,
                borderRadius: 100,
                top: isMobileVersion ? 1 : 4,
              }}
              onValuesChange={(value) => {
                if (value && value.length > 0) {
                  setDistance(value[0]);
                }
              }}
              values={[distance]}
              min={0}
              max={
                router?.query?.category === "venues"
                  ? 16
                  : router?.query?.category === "festivals"
                  ? 800
                  : 160
              }
              trackStyle={{
                height: isMobileVersion ? 4 : 10,
                backgroundColor: getClass("border-tabBottomColor"),
              }}
              containerStyle={{
                height: isMobileVersion ? 16 : 24,
                marginBottom: isMobileVersion ? 20 : 30,
              }}
              sliderLength={isMobileVersion ? Values.deviceWidth - 120 : 400}
            />
          </View>
        </View>
      ) : (
        <ScrollView ref={scrollRef} style={styles.list}>
          {props.defaultIsEmpty && search !== "" && (
            <>
              {items.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    styles.listItem,
                    isMobileVersion
                      ? {
                          paddingHorizontal: 40,
                          height: "auto",
                          paddingVertical: 8,
                        }
                      : {},
                    (locationSelected?.country_id === item.country_id &&
                      locationSelected?.id === item.id &&
                      item.country_id) ||
                    (locationSelected?.id === item.id && item.id) ||
                    (locationSelected?.state_id === item.state_id &&
                      locationSelected?.id === item.id &&
                      locationSelected?.country_id === item.country_id &&
                      locationSelected?.city_id === item.city_id &&
                      item.state_id &&
                      !item.country_id &&
                      !item.city_id &&
                      !item.id &&
                      !item.isWorldWide &&
                      !item.isVirtual) ||
                    (locationSelected?.isWorldWide === item.isWorldWide &&
                      item.isWorldWide) ||
                    (locationSelected?.isVirtual === item.isVirtual &&
                      item.isVirtual)
                      ? styles.listItemActive
                      : {},
                  ]}
                  onPress={() => {
                    if (props.withoutApply) {
                      props.onPressApply(item, distance);
                    } else {
                      setLocationSelected(item);
                    }
                  }}>
                  {item.name != undefined && (
                    <Text
                      style={[
                        styles.listItemName,
                        isMobileVersion ? { fontSize: 14 } : {},
                      ]}
                      numberOfLines={1}>
                      {item.name}
                    </Text>
                  )}
                  <Text
                    style={[
                      styles.listItemState,
                      isMobileVersion ? { fontSize: 14 } : {},
                    ]}
                    numberOfLines={1}>
                    {item.state_name != undefined &&
                      `${item.state_name}${item.country_name ? "," : ""} `}
                    {item.country_name && item.country_name}
                  </Text>
                </TouchableOpacity>
              ))}
            </>
          )}
          {!props.defaultIsEmpty && (
            <>
              {items.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    styles.listItem,
                    isMobileVersion
                      ? {
                          paddingHorizontal: 40,
                          height: "auto",
                          paddingVertical: 8,
                        }
                      : {},
                    (locationSelected?.country_id === item.country_id &&
                      locationSelected?.id === item.id &&
                      item.country_id) ||
                    (locationSelected?.id === item.id && item.id) ||
                    (locationSelected?.state_id === item.state_id &&
                      locationSelected?.id === item.id &&
                      locationSelected?.country_id === item.country_id &&
                      locationSelected?.city_id === item.city_id &&
                      item.state_id &&
                      !item.country_id &&
                      !item.city_id &&
                      !item.id &&
                      !item.isWorldWide &&
                      !item.isVirtual) ||
                    (locationSelected?.isWorldWide === item.isWorldWide &&
                      item.isWorldWide) ||
                    (locationSelected?.isVirtual === item.isVirtual &&
                      item.isVirtual)
                      ? styles.listItemActive
                      : {},
                  ]}
                  onPress={() => {
                    if (props.withoutApply) {
                      props.onPressApply(item, distance);
                    } else {
                      setLocationSelected(item);
                    }
                  }}>
                  {item.name != undefined && (
                    <Text
                      style={[
                        styles.listItemName,
                        isMobileVersion ? { fontSize: 14 } : {},
                      ]}
                      numberOfLines={1}>
                      {item.name}
                    </Text>
                  )}
                  <Text
                    style={[
                      styles.listItemState,
                      isMobileVersion ? { fontSize: 14 } : {},
                    ]}
                    numberOfLines={1}>
                    {item.state_name != undefined &&
                      `${item.state_name}${item.country_name ? "," : ""} `}
                    {item.country_name && item.country_name}
                  </Text>
                </TouchableOpacity>
              ))}
            </>
          )}
        </ScrollView>
      )}
      {!props.withoutApply && (
        <View style={styles.footerButton}>
          <View style={styles.footerItem}>
            <IconizedButton
              buttonTitle={t("key_button_cancel")}
              borderRadius={60}
              style={[
                styles.buttonStyle,
                { borderColor: getClass("border-black") },
              ]}
              bordered
              borderedTextColor={getClass("text-white")}
              onPress={props.onPressCancel}
              fontSize={isMobileVersion ? 12 : 16}
              isMobileVersion={isMobileVersion}
            />
          </View>
          <View style={styles.footerItem}>
            <IconizedButton
              buttonTitle={t("key_button_apply")}
              style={styles.buttonStyle}
              filled
              filledColor={
                process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia"
                  ? Colors.zooBlue
                  : process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
                  ? Colors.prefabBlack
                  : process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                  ? Colors.parkfulPink
                  : process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club"
                  ? Colors.resortPink
                  : process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub"
                  ? Colors.rooftopPink
                  : process.env.NEXT_PUBLIC_APP_NAME == "SpaClub"
                  ? Colors.spaBlue
                  : process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                  ? Colors.beachPink
                  : null
              }
              filledTextColor={
                process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                process.env.NEXT_PUBLIC_APP_NAME == "Parkful" ||
                process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club" ||
                process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub" ||
                process.env.NEXT_PUBLIC_APP_NAME == "SpaClub" ||
                process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                  ? Colors.white
                  : null
              }
              borderRadius={60}
              disabled={locationSelected === null || locationSelected === ""}
              onPress={() =>
                props.isDistance &&
                tempLocationSelected === null &&
                locationSelected?.name &&
                locationSelected?.name !== "World" &&
                locationSelected?.name !== "Virtual"
                  ? setTempLocationSelected(locationSelected)
                  : props.onPressApply(locationSelected, distance)
              }
              fontSize={isMobileVersion ? 12 : 16}
              isMobileVersion={isMobileVersion}
            />
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: getClass("text-white"),
    fontSize: 16,
    lineHeight: 20,
    fontFamily: Fonts.primaryBold,
  },
  titleIcon: {
    width: 16,
    height: 16,
    resizeMode: "contain",
    marginRight: 8,
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: getClass("border-spliterLine"),
    borderRadius: 60,
    paddingVertical: 9,
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: 30,
    height: 50,
    marginHorizontal: 6,
  },
  locationContent: {
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: Colors.placeholder,
  },
  locationIconClose: {
    width: 14,
    height: 14,
    resizeMode: "contain",
    marginRight: 4,
  },
  distanceContainer: {
    borderTopColor: getClass("border-tabBottomColor"),
    borderTopWidth: 1,
    marginHorizontal: -16,
    paddingHorizontal: 16,
    paddingTop: 28,
    paddingBottom: 18,
    marginBottom: -20,
  },
  distanceTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  distanceTitle: {
    fontFamily: Fonts.primaryRegular,
    color: getClass("text-white"),
    fontSize: 16,
  },
  distanceInfo: {
    fontFamily: Fonts.primaryRegular,
    color: Colors.placeholder,
    fontSize: 14,
  },
  list: {
    marginHorizontal: -16,
    maxHeight: 200,
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: 4,
    height: 40,
    paddingHorizontal: 48,
  },
  listItemActive: {
    backgroundColor: getClass("bg-listItemActive"),
  },
  listItemName: {
    fontFamily: Fonts.primaryRegular,
    color: getClass("text-white"),
    fontSize: 16,
    paddingRight: 8,
  },
  listItemState: {
    fontFamily: Fonts.primaryRegular,
    color: Colors.placeholder,
    fontSize: 16,
    paddingRight: 8,
    flex: 1,
  },
  listItemCountry: {
    fontFamily: Fonts.primaryRegular,
    color: Colors.placeholder,
    fontSize: 16,
  },
  footerButton: {
    borderTopColor: getClass("border-tabBottomColor"),
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingTop: 20,
    marginHorizontal: -16,
    marginBottom: -14,
    marginTop: 4,
    flex: 1,
  },
  footerItem: {
    paddingHorizontal: 6,
    flex: 1,
  },
  buttonStyle: {
    marginBottom: 16,
  },
});

export default LocationContainer;
