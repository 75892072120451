import React from "react";
import { CModal } from "../../atoms";
import PlaylistContainer from "./PlaylistContainer";

const PlaylistModal = React.forwardRef((props, ref) => {
  return (
    <CModal ref={ref} {...props}>
      <PlaylistContainer {...props} style={{ marginTop: 14 }} />
    </CModal>
  );
});
export default PlaylistModal;
