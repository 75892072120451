import React from "react";
import { useTranslation } from "react-i18next";
import { Images } from "../../../config";
import { CModal } from "../../atoms";
import LocationContainer from "./LocationContainer";

const LocationModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <CModal
      ref={ref}
      {...props}
      icon={Images.iconLocationPin}
      title={props.titleModal || t("key_location")}>
      <LocationContainer {...props} />
    </CModal>
  );
});
export default LocationModal;
