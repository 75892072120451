import React, { useEffect } from "react";
import { StyleSheet, View, ScrollView, Text } from "react-native-web";
import {
  CCheckBox,
  IconizedButton,
  IconizedTextInput,
  CCalendar,
  CImage,
} from "../../atoms";
import { useTranslation } from "react-i18next";
import { Colors, Constants, Fonts, Images, Values } from "../../../config";
import { LocationDao } from "../../../DAO";
import moment from "moment";
import ScrollPicker from "../../../../components_web/ScrollPicker";
import MultiSlider from "../../../../components_web/MultiSlider";
import { getClass } from "../../../helpers/formatHelpers";

const MultipleCheckContainer = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion, category } = props;
  const [multiSliderValue, setMultiSliderValue] = React.useState(
    props.checkedItemIds && props.checkedItemIds?.length > 0
      ? props.checkedItemIds
      : [0, 1000]
  );
  const [ageValue, setAgeValue] = React.useState(
    props.checkedItemIds && props.checkedItemIds?.length > 0
      ? props.checkedItemIds
      : ["Minimum", 0, "Or lower"]
  );
  const [dimensionLengthValue, setDimensionLengthValue] = React.useState(
    props.dimensionLengthSelected || 0
  );
  const [dimensionWidthValue, setDimensionWidthValue] = React.useState(
    props.dimensionWidthSelected || 0
  );
  const [dateAvailable, setDateAvailable] = React.useState("All");
  const [dateSelection, setDateSelection] = React.useState(
    props.dateType === "available"
      ? "Open"
      : props.checkedItemIds?.isUpcoming
      ? "Upcoming"
      : props.checkedItemIds?.isAnytime
      ? "Anytime"
      : category === Constants.categoryType.CATEGORY_ARTIST
      ? null
      : "Upcoming"
  );
  const [startingDate, setStartingDate] = React.useState(
    !props.checkedItemIds?.isUpcoming && !props.checkedItemIds?.isAnytime
      ? props.checkedItemIds?.start || new Date()
      : new Date()
  );
  const [endingDate, setEndingDate] = React.useState(
    !props.checkedItemIds?.isUpcoming && !props.checkedItemIds?.isAnytime
      ? props.checkedItemIds?.end
      : null
  );
  const [markedDate, setMarkedDate] = React.useState(null);
  const getDaysArray = function (start, end) {
    let arr = [];
    let dt = new Date(start);
    for (; dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };
  const handlePickDays = (days) => {
    setDateSelection("");
    setStartingDate(moment(days[0]).format("YYYY-MM-DD"));
    setEndingDate(moment(days[1]).format("YYYY-MM-DD"));
  };

  const onLoadCountry = () => {
    const params = {
      keyword: search,
      isCountryOnly: 1,
      pageSize: 1000,
    };
    LocationDao.find(params).then((locations) => {
      setDataList(
        locations
          .filter((item) => item.country)
          .map((item) => ({
            ...item,
            id: item.country_id,
            label: item.country,
            isSelected: props.checkedItemIds.includes(item.country_id) || false,
          }))
      );
    });
  };

  useEffect(() => {
    selectDate().then((date) => {
      setMarkedDate(date);
    });
  }, [startingDate, endingDate]);

  useEffect(() => {
    if (props.isCountry) {
      onLoadCountry();
    }
  }, []);

  const handlePickUpcoming = () => {
    setDateSelection("Upcoming");
    setMarkedDate(null);
    setStartingDate(null);
    setEndingDate(null);
  };
  const handlePickAnytime = () => {
    setDateSelection("Anytime");
    setMarkedDate(null);
    setStartingDate(null);
    setEndingDate(null);
  };
  const selectDate = async () => {
    const daylist = endingDate
      ? getDaysArray(new Date(startingDate), new Date(endingDate))
      : [new Date(startingDate)];
    daylist.map((v) => v.toISOString().slice(0, 10)).join("");
    let _markedDates = {};
    daylist.forEach((item, index) => {
      let _date;
      if (index === 0) {
        _date = {
          [new Date(item).toISOString().slice(0, 10)]: {
            startingDay: endingDate ? true : false,
            endingDay:
              moment(startingDate).format("YYYY-MM-DD") ===
              moment(endingDate).format("YYYY-MM-DD")
                ? true
                : false,
            color: Colors.gradient2,
          },
        };
        Object.assign(_markedDates, _date);
      } else if (index === daylist.length - 1) {
        _date = {
          [new Date(item).toISOString().slice(0, 10)]: {
            endingDay: true,
            color: Colors.gradient2,
          },
        };
        Object.assign(_markedDates, _date);
      } else {
        _date = {
          [new Date(item).toISOString().slice(0, 10)]: { color: "gray" },
        };
        Object.assign(_markedDates, _date);
      }
    });
    return _markedDates;
  };
  const [search, setSearch] = React.useState("");
  const [dataList, setDataList] = React.useState(
    props.items.map((item) => ({
      ...item,
      isSelected:
        props.isLineup ||
        props.isEventSize ||
        props.isCompanyHomeSize ||
        props.isRangeValue
          ? props.checkedItemIds?.some(
              (i) =>
                i.value === item.value?.value &&
                i.minValue === item.value?.minValue
            )
          : props.checkedItemIds.includes(item.tagId ? item.tagId : item.id) ||
            false,
    })) || []
  );

  const onPressItem = (item) => {
    if (props.onPressItem) {
      props.onPressItem(item);
    }
  };

  const renderPickerItem = (data, index, isSelected) => {
    return (
      <View
        style={{
          height: 44,
          alignSelf: "stretch",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Text
          style={[
            styles.pickerItemLabel,
            isSelected && { color: Colors.white },
          ]}>
          {data}
        </Text>
      </View>
    );
  };

  const pad = (n, width, z) => {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };

  const constructArray = (startNum, endNum) => {
    const yearArr = [];
    for (let i = startNum; i <= endNum; i++) {
      yearArr.push(pad(i, 2, "0"));
    }
    return yearArr;
  };

  const renderItems = () => {
    if (props.isDate) {
      return (
        <View style={{ marginBottom: -20 }}>
          {props.dateType === "available" && (
            <View style={styles.row}>
              <CCheckBox
                label="All"
                checked={dateAvailable === "All"}
                onPress={() => setDateAvailable("All")}
              />
              <CCheckBox
                label="Available"
                checked={dateAvailable === "Available"}
                onPress={() => setDateAvailable("Available")}
              />
              <CCheckBox
                label="Not Available"
                checked={dateAvailable === "Not Available"}
                onPress={() => setDateAvailable("Not Available")}
              />
            </View>
          )}
          {props.dateType === "available" && (
            <View style={styles.row2}>
              <IconizedButton
                buttonTitle="Open"
                colored={dateSelection === "Open"}
                filled={dateSelection !== "Open"}
                filledColor={Colors.grayBackground}
                filledTextColor={Colors.grey2}
                onPress={() => setDateSelection("Open")}
                borderRadius={4}
                style={{ height: 24, flex: 1, marginRight: 10 }}
                isMobileVersion={isMobileVersion}
              />
              <IconizedButton
                buttonTitle="Closed"
                colored={dateSelection === "Closed"}
                filled={dateSelection !== "Closed"}
                filledColor={Colors.grayBackground}
                filledTextColor={Colors.grey2}
                onPress={() => setDateSelection("Closed")}
                borderRadius={4}
                style={{ height: 24, flex: 1 }}
                isMobileVersion={isMobileVersion}
              />
            </View>
          )}
          {props.dateType === "anytime" && (
            <View style={styles.row2}>
              <IconizedButton
                buttonTitle="Upcoming"
                colored={dateSelection === "Upcoming"}
                filled={dateSelection !== "Upcoming"}
                filledColor={Colors.grayBackground}
                filledTextColor={Colors.grey2}
                onPress={() => handlePickUpcoming()}
                borderRadius={4}
                style={{
                  height: isMobileVersion ? 24 : 40,
                  flex: 1,
                  marginRight: 10,
                }}
                isMobileVersion={isMobileVersion}
              />
              <IconizedButton
                buttonTitle="Anytime"
                colored={dateSelection === "Anytime"}
                filled={dateSelection !== "Anytime"}
                filledColor={Colors.grayBackground}
                filledTextColor={Colors.grey2}
                onPress={() => handlePickAnytime()}
                borderRadius={4}
                style={{
                  height: isMobileVersion ? 24 : 40,
                  flex: 1,
                  marginRight: 0,
                }}
                isMobileVersion={isMobileVersion}
              />
            </View>
          )}
          <CCalendar
            markingType={"period"}
            markedDates={markedDate}
            selected={[
              moment(startingDate).toDate(),
              moment(endingDate).toDate(),
            ]}
            isSmall
            withRange={true}
            onDayPress={(value) => handlePickDays(value)}
            isMobileVersion={isMobileVersion}
          />
        </View>
      );
    } else if (props.isAge) {
      return (
        <View style={styles.ageContainer}>
          <Text
            style={[
              styles.ageLabel,
              { color: "white" },
              isMobileVersion ? { fontSize: 10 } : {},
            ]}>
            {ageValue[0] || "Minimum"}
          </Text>
          <View
            style={{ paddingHorizontal: isMobileVersion ? 6 : 12, flex: 1 }}>
            <div className="flex flex-row items-center">
              <View
                style={[
                  styles.arrowContainer,
                  isMobileVersion ? { width: 30, height: 30 } : {},
                ]}
                onClick={() =>
                  setAgeValue([
                    ageValue[0] || "Minimum",
                    ageValue[1] - 1 < 0 ? 0 : parseInt(ageValue[1] - 1),
                    ageValue[2] || "Or lower",
                  ])
                }>
                <CImage
                  source={Images.iconAngleLeftWhite}
                  width={16}
                  height={16}
                  layout="fixed"
                />
              </View>
              <div
                className={`bg-grayBackground rounded-full flex flex-row items-center justify-center px-3 mx-2 ${
                  isMobileVersion ? "h-8" : "h-10"
                } w-full`}>
                <input
                  type="text"
                  className="bg-grayBackground font-regular sm:text-base text-xs text-white outline-0 text-center w-full"
                  value={ageValue[1]}
                  onChange={(e) => {
                    setAgeValue([
                      ageValue[0] || "Minimum",
                      e.target.value > 60
                        ? 60
                        : e.target.value < 0
                        ? 0
                        : parseInt(e.target.value || 0),
                      ageValue[2] || "Or lower",
                    ]);
                  }}
                  min={0}
                  max={60}
                />
              </div>
              <View
                style={[
                  styles.arrowContainer,
                  isMobileVersion ? { width: 30, height: 30 } : {},
                ]}
                onClick={() =>
                  setAgeValue([
                    ageValue[0] || "Minimum",
                    ageValue[1] + 1 > 60 ? 60 : parseInt(ageValue[1] + 1),
                    ageValue[2] || "Or lower",
                  ])
                }>
                <CImage
                  source={Images.iconAngleRight}
                  width={16}
                  height={16}
                  layout="fixed"
                />
              </View>
            </div>
          </View>
          <Text
            style={[
              styles.ageLabel,
              { color: "white" },
              isMobileVersion ? { fontSize: 10 } : {},
            ]}>
            {ageValue[2] || "Or lower"}
          </Text>
        </View>
      );
    } else if (props.isPrice) {
      return (
        <View style={styles.sliderContainer}>
          <Text style={styles.sliderTitle}>
            {multiSliderValue[0] === 0
              ? "Free"
              : `${
                  multiSliderValue[0] ? "$" + multiSliderValue[0] : "Free"
                }`}{" "}
            -{" "}
            {multiSliderValue[1] === 1000
              ? "Max"
              : `$${multiSliderValue[1] || 50}`}
          </Text>
          <MultiSlider
            values={[multiSliderValue[0] || 0, multiSliderValue[1] || 50]}
            trackGradientColors={["#F526D6", "#A408FE", "#44B3F8"]}
            markerStyle={[
              styles.sliderMarker,
              isMobileVersion
                ? {
                    height: 16,
                    width: 16,
                    top: 2,
                  }
                : {},
            ]}
            onValuesChange={(value) => setMultiSliderValue(value)}
            min={0}
            max={1000}
            step={5}
            sliderLength={isMobileVersion ? Values.deviceWidth - 145 : 364}
            trackStyle={{
              height: isMobileVersion ? 4 : 10,
              backgroundColor: "#343434",
            }}
          />
        </View>
      );
    } else {
      return (
        <View>
          {dataList
            .filter((item) =>
              props.isCountry
                ? search !== "" && search.length > 2
                  ? item?.label
                      ?.toLowerCase()
                      .includes(search?.toLocaleLowerCase())
                  : false
                : item
            )
            .map((item, index) => {
              const isLast = index == dataList.length - 1;
              if (item.isSeparator) {
                return (
                  <div className="relative w-full mb-4">
                    <View style={styles.separatorLine} />
                  </div>
                );
              }
              if (item.isTextOnly) {
                return (
                  <div className="relative w-full mb-4">
                    <Text
                      style={{
                        color: Colors.grayText2,
                        fontFamily: Fonts.primaryRegular,
                        fontSize: isMobileVersion ? 12 : 14,
                        lineHeight: isMobileVersion ? 16 : 18,
                      }}>
                      {item.label}
                    </Text>
                  </div>
                );
              }

              return (
                <CCheckBox
                  isMobileVersion={isMobileVersion}
                  key={"check" + index}
                  label={item.label}
                  prefixIcon={item.prefixIcon}
                  suffix={item.suffix}
                  isDisabled={item.isDisabled}
                  type={props.isSingle ? "radio" : "check"}
                  checked={item.isSelected}
                  onPress={() => {
                    const currentSelected = dataList.find(
                      (item) => item.isSelected
                    )?.tagId;
                    const newData = dataList.map((row) => {
                      if (
                        row.tagId
                          ? row.tagId === item.tagId
                          : row.id === item.id
                      ) {
                        if (props.isMinimumOneSelect) {
                          if (currentSelected !== row.tagId) {
                            return {
                              ...row,
                              isSelected: !row.isSelected,
                            };
                          }
                        } else {
                          return {
                            ...row,
                            isSelected: !row.isSelected,
                          };
                        }
                      } else {
                        if (props.isSingle) {
                          return {
                            ...row,
                            isSelected: false,
                          };
                        }
                      }

                      return row;
                    });
                    setDataList(newData);
                  }}
                  style={[
                    !isLast && styles.itemStyle,
                    isMobileVersion ? { marginBottom: 18 } : {},
                  ]}
                />
              );
            })}

          {props.isDimension && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View>
                <Text
                  style={[
                    styles.ageLabel,
                    { color: "white", marginTop: 20 },
                    isMobileVersion ? { fontSize: 10 } : {},
                  ]}>
                  Length
                </Text>
                <View style={styles.ageContainer}>
                  <View
                    style={{
                      marginRight: 10,
                      flex: 1,
                    }}>
                    <div className="flex flex-row items-center">
                      <div
                        className={`bg-grayBackground rounded-full flex flex-row items-center justify-center px-3 ${
                          isMobileVersion ? "h-8" : "h-10"
                        } w-[150px]`}>
                        <input
                          type="text"
                          className="bg-grayBackground font-regular sm:text-base text-xs text-white outline-0 text-center w-full"
                          value={dimensionLengthValue}
                          onChange={(e) => {
                            setDimensionLengthValue(
                              parseInt(e.target.value || 0)
                            );
                          }}
                          min={0}
                          max={60}
                        />
                      </div>
                    </div>
                  </View>
                </View>
              </View>

              <View>
                <Text
                  style={[
                    styles.ageLabel,
                    { color: "white", marginTop: 20 },
                    isMobileVersion ? { fontSize: 10 } : {},
                  ]}>
                  Width
                </Text>
                <View style={styles.ageContainer}>
                  <View
                    style={{
                      flex: 1,
                    }}>
                    <div className="flex flex-row items-center">
                      <div
                        className={`bg-grayBackground rounded-full flex flex-row items-center justify-center px-3 ${
                          isMobileVersion ? "h-8" : "h-10"
                        } w-[150px]`}>
                        <input
                          type="text"
                          className="bg-grayBackground font-regular sm:text-base text-xs text-white outline-0 text-center w-full"
                          value={dimensionWidthValue}
                          onChange={(e) => {
                            setDimensionWidthValue(
                              parseInt(e.target.value || 0)
                            );
                          }}
                          min={0}
                          max={60}
                        />
                      </div>
                    </div>
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
      );
    }
  };
  return (
    <View style={[styles.container, props.style]}>
      <style jsx global>{`
        * {
          scrollbar-width: thin;
          scrollbar-color: ${getClass("scrollbar")} transparent;
        }

        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        *::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 0px;
        }

        *::-webkit-scrollbar-thumb {
          background-color: ${getClass("scrollbar")};
          border-radius: 14px;
          border: 3px solid transparent;
        }
      `}</style>
      {props.isSearchable && (
        <IconizedTextInput
          placeholder={t("key_search")}
          bordered
          onChangeText={(text) => setSearch(text)}
          value={search}
          maxLength={64}
          autoCapitalize="none"
          autoCorrect={false}
          containerStyle={{
            marginBottom: isMobileVersion ? 16 : 20,
            marginTop: isMobileVersion ? 16 : 24,
            marginHorizontal: isMobileVersion ? 7 : 6,
            height: isMobileVersion ? 38 : 50,
          }}
          returnKeyType="search"
          showClose={search !== ""}
          onPressClose={() => setSearch("")}
          isMobileVersion={isMobileVersion}
        />
      )}
      <ScrollView
        bounces={!props.isAge && !props.isPrice}
        style={[
          styles.wrapper,
          props.isDate ? { maxHeight: 600 } : {},
          isMobileVersion ? { paddingVertical: 0, paddingHorizontal: 26 } : {},
        ]}>
        {renderItems()}
      </ScrollView>
      <View style={styles.footerButton}>
        <View style={styles.footerItem}>
          <IconizedButton
            buttonTitle={props.buttonTitleClear || t("key_button_clear")}
            style={{
              borderColor: getClass("border-black"),
            }}
            borderedTextColor={getClass("text-white")}
            bordered
            borderRadius={60}
            onPress={() => {
              setMultiSliderValue([0, 50]);
              setAgeValue("Minimum", 17, "Or lower");
              const newData = dataList.map((item) => ({
                ...item,
                isSelected: false,
              }));
              setDataList(newData);
              props.onPressClear();
              setMarkedDate({});
              setStartingDate(null);
              setEndingDate(null);
            }}
            isMobileVersion={isMobileVersion}
          />
        </View>
        <View style={styles.footerItem}>
          <IconizedButton
            buttonTitle={t("key_button_apply")}
            filled
            filledColor={
              process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia"
                ? Colors.zooBlue
                : process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
                ? Colors.prefabBlack
                : process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                ? Colors.parkfulPink
                : process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                ? Colors.castleBlack
                : process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club"
                ? Colors.resortPink
                : process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub"
                ? Colors.rooftopPink
                : process.env.NEXT_PUBLIC_APP_NAME == "SpaClub"
                ? Colors.spaBlue
                : process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                ? Colors.beachPink
                : null
            }
            filledTextColor={
              process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
              process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Parkful" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia" ||
              process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club" ||
              process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub" ||
              process.env.NEXT_PUBLIC_APP_NAME == "SpaClub" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                ? Colors.white
                : null
            }
            borderRadius={60}
            onPress={() => {
              onPressItem(
                props.isPrice
                  ? multiSliderValue
                  : props.isLineup ||
                    props.isEventSize ||
                    props.isCompanyHomeSize ||
                    props.isRangeValue
                  ? dataList
                      .filter((item) => item.isSelected === true)
                      .map((item) => (item.value ? item.value : item.id))
                  : props.isDate
                  ? dateSelection === "Anytime"
                    ? { isUpcoming: false, isAnytime: true }
                    : dateSelection === "Upcoming"
                    ? {
                        isUpcoming: true,
                        isAnytime: false,
                      }
                    : {
                        start: startingDate,
                        end: endingDate || startingDate,
                      }
                  : props.isAge
                  ? ageValue
                  : dataList
                      .filter((item) => item.isSelected === true)
                      .map((item) => (item.tagId ? item.tagId : item.id))
              );
            }}
            isMobileVersion={isMobileVersion}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  wrapper: {
    maxHeight: 350,
    paddingHorizontal: 32,
    paddingVertical: 20,
  },
  itemStyle: {
    marginBottom: 20,
  },
  separatorLine: {
    backgroundColor: getClass("bg-card-continent"),
    height: 1,
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: 14,
    marginTop: 14,
  },
  row2: {
    borderTopColor: getClass("bg-icon-gray1"),
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingVertical: 14,
  },
  sliderContainer: {
    paddingHorizontal: 10,
  },
  sliderTitle: {
    fontFamily: Fonts.primaryRegular,
    color: Colors.white,
    fontSize: 14,
    textAlign: "center",
  },
  sliderMarker: {
    backgroundColor: Colors.white,
    height: 24,
    width: 24,
    borderRadius: 100,
    top: 5,
  },
  ageContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingVertical: 16,
    position: "relative",
  },
  ageLabel: {
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    fontSize: 16,
  },
  ageList: {
    position: "relative",
  },
  pickerItemLabel: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: Fonts.primaryBold,
    color: Colors.grey,
  },
  ageItem: {
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    fontSize: 14,
  },
  ageItemPrevNext: {
    fontFamily: Fonts.primaryRegular,
    color: Colors.grey2,
    fontSize: 14,
    position: "absolute",
    width: 50,
  },
  footerButton: {
    borderTopWidth: 1,
    borderTopColor: getClass("bg-icon-gray1"),
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingTop: 16,
    marginTop: 16,
    marginHorizontal: -16,
  },
  footerItem: {
    flex: 1,
    paddingHorizontal: 8,
  },
  arrowContainer: {
    width: 40,
    height: 40,
    borderWidth: 1,
    borderRadius: 60,
    borderColor: Colors.grayBorder,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
});

export default MultipleCheckContainer;
