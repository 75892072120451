import { StyleSheet, Text, View, TouchableOpacity } from "react-native-web";
import React from "react";
import { Colors, Fonts } from "../../../config";
import { CModal } from "../../atoms";

const ConfirmationModal = React.forwardRef((props, ref) => {
  return (
    <CModal ref={ref} {...props} title={props.title}>
      <View style={styles.container}>
        <View style={{ alignItems: "center", marginBottom: 20 }}>
          <Text style={styles.h3}>{props.desc}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            borderTopColor: Colors.tabBottomColor,
            borderTopWidth: 1,
          }}
        >
          <TouchableOpacity
            style={[
              styles.modalButtonContainer,
              {
                backgroundColor: Colors.black,
                borderColor: Colors.white30,
                borderWidth: 1,
              },
            ]}
            onPress={props.leftButtonAction}
          >
            <Text style={[styles.modalButtonText, { color: Colors.white }]}>
              {props.leftButtonText}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.modalButtonContainer}
            onPress={props.rightButtonAction}
          >
            <Text style={[styles.modalButtonText, { color: Colors.black }]}>
              {props.rightButtonText}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </CModal>
  );
});

export default ConfirmationModal;

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  },
  modalTitle: {
    color: Colors.white,
    fontSize: 16,
    fontFamily: Fonts.primaryBold,
    marginBottom: 10,
    textAlign: "center",
  },
  h3: {
    color: Colors.white,
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    lineHeight: 18,
    textAlign: "center",
  },
  modalButtonContainer: {
    paddingVertical: 8,
    width: "45%",
    backgroundColor: Colors.white,
    borderRadius: 60,
    marginTop: 14,
    justifyContent: "center",
    alignItems: "center",
  },
  modalButtonText: {
    fontSize: 16,
    fontFamily: Fonts.primaryBold,
  },
  iconClose: {
    position: "absolute",
    right: 18,
    top: 18,
  },
});
