import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  StyleSheet,
  View,
  FlatList,
  Modal,
  Image,
  Text,
  TouchableOpacity,
} from "react-native-web";
import { Colors, Constants, Fonts, Images, Values } from "../../../config";
import { CImage, IconizedButton } from "../../atoms";
import { getClass } from "../../../helpers/formatHelpers";

const MenuItem = (props) => {
  const { isMobileVersion } = props;
  return (
    <TouchableOpacity
      style={styles.menuItemContainer}
      onPress={() => props.onPressItem(props.item)}>
      {props.item.icon && (
        <CImage
          width={props.item.title === "Insights" ? 18 : 16}
          height={props.item.title === "Insights" ? 18 : 16}
          layout="fixed"
          source={props.item.icon}
          style={styles.icon}
          alt={props.item.title?.toLowerCase()}
        />
      )}
      <Text
        style={[
          styles.menuItemText,
          isMobileVersion ? { fontSize: 12, lineHeight: 12 } : {},
        ]}>
        {props.item.title}
      </Text>
      {props.item.suffix && (
        <Text
          style={[
            styles.menuItemSuffixText,
            isMobileVersion ? { fontSize: 12, lineHeight: 12 } : {},
          ]}>
          {props.item.suffix}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const PopupMenuListModal = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  const onPressItem = (item) => {
    if (props.onPressItem) {
      props.onPressItem(item);
    }
  };
  const renderItems = () => {
    if (!props.items) return [];
    return props.items.map((item, index) => (
      <MenuItem
        key={index + "item"}
        item={item}
        onPressItem={onPressItem}
        isMobileVersion={isMobileVersion}
      />
    ));
  };
  const onClose = () => {
    if (props.onClose) {
      document.body.style.overflow = "auto";
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [props.isVisible]);

  return (
    <View style={[props.style]}>
      <Modal transparent visible={props.isVisible}>
        <View style={styles.dim}>
          <View
            style={[
              styles.modalContainer,
              isMobileVersion ? { width: "90%" } : {},
            ]}>
            <View style={styles.bodyContainer}>{renderItems()}</View>
            <View
              style={[
                styles.bottomContainer,
                isMobileVersion ? { height: 72 } : {},
              ]}>
              <IconizedButton
                buttonTitle={t("key_close")}
                onPress={() => {
                  onClose();
                }}
                fontSize={isMobileVersion ? 12 : 16}
                style={[
                  styles.buttonStyle,
                  isMobileVersion ? { height: 32 } : {},
                ]}
                filled
                filledColor={
                  process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia"
                    ? Colors.zooBlue
                    : process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
                    ? Colors.prefabBlack
                    : process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? Colors.parkfulPink
                    : process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club"
                    ? Colors.resortPink
                    : process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? Colors.castleBlack
                    : process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub"
                    ? Colors.rooftopPink
                    : process.env.NEXT_PUBLIC_APP_NAME == "SpaClub"
                    ? Colors.spaBlue
                    : process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                    ? Colors.beachPink
                    : null
                }
                filledTextColor={
                  process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Parkful" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "SpaClub" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                    ? Colors.white
                    : null
                }
              />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  dim: {
    backgroundColor: "rgba(89, 89, 89, 0.45)",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    margin: 32,
    position: "absolute",
    flex: 1,
    backgroundColor: getClass("background"),
    borderWidth: 0,
    justifyContent: "center",
    borderRadius: 25,
    maxWidth: 480,
    width: "100%",
    zIndex: 500,
  },
  bodyContainer: {
    flex: 1,
  },
  bottomContainer: {
    height: 90,
    alignSelf: "stretch",
  },
  title: {
    color: Colors.gray,
    fontSize: Values.fontSize.medium,
    marginTop: 8,
    marginBottom: 8,
  },
  check: {
    width: 16,
    height: 16,
    marginStart: 10,
    marginEnd: 10,
  },

  closeButton: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 50,
    alignSelf: "center",
  },

  menuItemContainer: {
    paddingLeft: 30,
    height: 60,
    borderBottomColor: getClass("border-tabBottomColor"),
    borderBottomWidth: 1,
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    height: 16,
    width: 16,
    resizeMode: "contain",
    marginRight: 6,
  },
  menuItemText: {
    marginLeft: 15,
    fontSize: 16,
    lineHeight: 20,
    fontFamily: Fonts.primaryRegular,
    color: getClass("text-white"),
  },
  menuItemSuffixText: {
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    color: getClass("text-white70"),
    marginLeft: 6,
  },
  buttonStyle: {
    height: 50,
    margin: 20,
  },
});

export default PopupMenuListModal;
