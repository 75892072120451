import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Modal,
} from "react-native-web";
// import Orientation, {
//   OrientationType,
//   useOrientationChange,
// } from "react-native-orientation-locker";

// import PhotoView from "react-native-photo-view";
import { CImage } from "../../../atoms";
import { Images, Values } from "../../../../config";

const FullScreenImageModal = (props) => {
  const [orientation, setOrientation] = useState("PORTRAIT");
  const getSafeAreaStyle = (orientation) => {
    // if (orientation == OrientationType.PORTRAIT) {
    //   return {
    //     marginTop: Values.statusBarHeight,
    //     marginBottom: Values.bottomSpace,
    //   };
    // } else if (orientation == OrientationType["LANDSCAPE-LEFT"]) {
    //   return {
    //     marginLeft: Values.statusBarHeight,
    //     marginRight: Values.bottomSpace,
    //   };
    // } else if (orientation == OrientationType["LANDSCAPE-RIGHT"]) {
    //   return {
    //     paddingRight: Values.statusBarHeight,
    //     marginLeft: Values.bottomSpace,
    //   };
    // }
  };
  useEffect(() => {
    if (props.isVisible) {
      // Orientation.unlockAllOrientations();
    } else {
      // Orientation.lockToPortrait();
    }
  }, [props.isVisible]);

  // useOrientationChange((orientation) => {
  //   setOrientation(orientation);
  // });

  const imageView = () => {
    return (
      <CImage
        imgUrl={props.image}
        style={styles.backgroundVideo}
        objectFit="contain"
      />
    );
  };
  const renderCloseButton = () => {
    return (
      <TouchableOpacity
        style={{
          width: 60,
          height: 60,
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 14,
          right: 14,
        }}
        onPress={() => {
          if (props.closeModal) {
            props.closeModal();
          }
        }}
      >
        <CImage
          source={Images.iconClose}
          width={30}
          height={30}
          layout="fixed"
        />
      </TouchableOpacity>
    );
  };
  const containerStyle = getSafeAreaStyle(orientation);
  return (
    <Modal
      animationType={"fade"}
      supportedOrientations={["portrait", "landscape"]}
      transparent={true}
      onRequestClose={() => props.closeModal()}
      visible={props.isVisible}
      style={{ margin: 0 }}
    >
      <View style={{ flex: 1, backgroundColor: "black" }}>
        <View style={[{ flex: 1, backgroundColor: "black" }, containerStyle]}>
          {imageView()}
          {renderCloseButton()}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    height: 200,
  },
  imgContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  videoPlayerIcon: {
    width: 60,
    height: 60,
    resizeMode: "contain",
  },
  ModalOutsideContainer: {
    flex: 1,
  },
  ModalContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  ModalWrapper: {
    flex: 1,
    backgroundColor: "black",
  },
  portraitWrapper: {
    marginTop: Values.statusBarHeight,
    marginBottom: Values.bottomSpace,
  },
  ModalBox: {
    width: "85%",
    backgroundColor: "#fff",
    paddingTop: 10,
    paddingHorizontal: 6,
    borderRadius: 4,
    opacity: 1,
  },
  VideoPlayerContainer: {
    width: "100%",
    height: 150,
  },
  VideoTitle: {
    paddingVertical: 8,
    fontSize: 18,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: 16,
    top: 16,
    width: 30,
    height: 30,
    backgroundColor: "#00000050",
    borderRadius: 15,
  },
  imageFull: {
    width: "100%",
    height: "100%",
  },
  backgroundVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
  },
});
export default FullScreenImageModal;
