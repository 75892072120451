import React from 'react'
import { useTranslation } from 'react-i18next'
import { Images } from '../../../config'
import { CModal } from '../../atoms'
import FollowContainer from './FollowContainer'

const FollowModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation()

  return (
    <CModal
      ref={ref}
      {...props}
      title={t('key_follow')}
      icon={Images.iconPlusColored}>
      <FollowContainer {...props} />
    </CModal>
  )
})
export default FollowModal
